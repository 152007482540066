import { Close } from "@mui/icons-material";
import {
  Box,
  Dialog,
  Grid,
  IconButton,
  Slide,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import DialogCustomized from "../../../components/Dialog/DialogCustomized";
import FileViewer from "../../../components/fileViewer/FileViewer";
import AttachmentListItem, { iconsMap } from "./AttachmentListItem";

const fileUrls = [
  "https://processflow-media.s3.ap-south-1.amazonaws.com/Screenshot+(101).png",
  "https://processflow-media.s3.ap-south-1.amazonaws.com/Binary_Search_1.pdf",
  "https://processflow-media.s3.ap-south-1.amazonaws.com/cars24-car-price-clean2.csv",
  "https://processflow-media.s3.ap-south-1.amazonaws.com/CS7200-Spring-2022-Assignment-2.docx",
];

type allAttachmenttype = {
  open: boolean;
  onClose: () => void;
  data: any[];
};
function AllAttachment({ data, open, onClose }: allAttachmenttype) {
  const theme: any = useTheme();
  const [selectedFile, setSelectedFile] = React.useState(data?.[0].file_url);
  const [fullPreview, setFullPreview] = React.useState(false);

  function handleClick(url: string) {
    setSelectedFile(url);
  }

  function getFileNameFromUrl(url: string) {
    const parts = url?.split("/") ?? [];
    return parts?.[parts?.length - 1] ?? "";
  }

  function getFileTypeFromUrl(url: string) {
    const fileName = getFileNameFromUrl(url);
    const parts = fileName?.split(".") ?? [];
    return parts?.[parts.length - 1] ?? "";
  }

  return (
    <>
      <DialogCustomized
        open={fullPreview}
        handleClose={() => setFullPreview(false)}
        fullWidth
        maxWidth="xl"
        content={
          <Box width={"100%"} height={"70vh"}>
            <FileViewer filepath={selectedFile} height={"60vh"} />
          </Box>
        }
        title={"tole"}
        actions={<></>}
      />
      <AttachmentDrawer open={open} onClose={onClose}>
        <Grid container>
          <Grid item md={8.5} maxHeight={"100vh"} overflow="auto">
            <Stack
              alignItems="center"
              direction="row"
              padding={1}
              paddingBottom={0}
              borderBottom={`1px solid ${theme.palette.border.main}`}
              borderRight={`1px solid ${theme.palette.border.main}`}
              paddingLeft={2}
            >
              {/* @ts-ignore */}
              {iconsMap[getFileTypeFromUrl(selectedFile)] || iconsMap.default}
              <Typography variant="h5" padding={1.3}>
                {getFileNameFromUrl(selectedFile)}
              </Typography>
            </Stack>
            <Box height={"calc(100vh - 50px)"} overflow="auto">
              <FileViewer height={200} filepath={selectedFile} />
            </Box>
          </Grid>
          <Grid item xs={12} md={3.5}>
            <Box>
              <Stack
                alignItems="center"
                justifyContent="space-between"
                direction="row"
                padding={1}
                paddingBottom={0}
                borderBottom={`1px solid ${theme.palette.border.main}`}
              >
                <Typography variant="h5">All Files</Typography>
                <IconButton onClick={onClose}>
                  <Close />
                </IconButton>
              </Stack>
              {/* ++++++++++++++++++++++++++++++ Preview ++++++++++++++++++++++++++++++++++ */}
              <Box padding={1.5} paddingBottom={0}>
                <Stack
                  spacing={0.5}
                  marginTop={1}
                  paddingRight={1}
                  sx={{
                    height: "calc(100vh - 300px)",
                    overflow: "auto",
                  }}
                >
                  {data?.map((item) => (
                    <AttachmentListItem
                      fileObj={item}
                      fileUrl={item.file_url}
                      onClick={handleClick}
                      selected={item.file_url === selectedFile}
                    />
                  ))}
                </Stack>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </AttachmentDrawer>
    </>
  );
}

export default AllAttachment;

type drawertypes = {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
};

const Transition = React.forwardRef(function Transition(props: any, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function AttachmentDrawer({ open, onClose, children }: drawertypes) {
  return (
    // <Dialog
    //   fullScreen
    //   open={open}
    //   onClose={onClose}
    //   TransitionComponent={Transition}
    // >
    <>{children}</>
    // </Dialog>
  );
}
