import { Close, EmailOutlined } from "@mui/icons-material";
import {
  Avatar,
  Box,
  ButtonBase,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React, { Component, RefObject } from "react";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { FaRegBuilding } from "react-icons/fa";
import InfiniteScroll from "react-infinite-scroll-component";
import { FormattedMessage } from "react-intl";
import { ResizableBox } from "react-resizable";
import {
  createNodeUser,
  listOrganizationStaffs,
  token,
} from "../../apis/organization"; // Import the new function
import "./NodeUserList.css"; // Import sidebar styles

// Define the props interface
interface Sidebar2Props {
  show: boolean;
  onClose: () => void; // Function to handle closing the sidebar
  selectedNodeId: string; // Node ID to be passed
  nodeName: string; // Node name,
  locale?: string;
}

// Define the state interface
interface Sidebar2State {
  searchTerm: string;
  count: number;
  next: string;
  hasMore: boolean;
  selectedCategory: string; // Current selected label
  hierarchy: Array<{
    hierarchy: string;
    users: Array<{
      name: string;
      uuid: string;
      profile_img: string;
      is_selected: string;
    }>;
  }>; // Hierarchy structure
  scrollPosition: number; // For controlling scroll movement
  selectedItems: string[]; // Array to track selected items
  isLoading: boolean; // Loading state
  filteredUsers: any[];
  containerWidth: number;
}

class NodeUserList extends Component<Sidebar2Props, Sidebar2State> {
  categoryContainerRef: RefObject<HTMLDivElement>;

  constructor(props: Sidebar2Props) {
    super(props);
    this.state = {
      hasMore: true,
      searchTerm: "",
      selectedCategory: "All", // Initially set to 'All'
      hierarchy: [], // Initialize hierarchy
      scrollPosition: 0, // For controlling scroll movement
      selectedItems: [], // Initialize selected items
      isLoading: true, // Initially loading
      filteredUsers: [],
      containerWidth: 650,
      count: 0,
      next: "",
    };

    // Reference to category container for scrolling
    this.categoryContainerRef = React.createRef();
  }

  componentDidMount() {
    this.fetchHierarchy(); // Fetch hierarchy on component mount
  }

  componentDidUpdate(prevProps: Sidebar2Props) {
    if (prevProps.selectedNodeId !== this.props.selectedNodeId) {
      // Refetch hierarchy data if a new node is selected
      this.fetchHierarchy();
    }
  }

  fetchHierarchy = async () => {
    try {
      this.setState({ isLoading: true }); // Start loading
      const data = await listOrganizationStaffs(this.props.selectedNodeId); // Fetch hierarchy from your API

      // Get users that are already selected
      const preSelectedUsers = data.results
        .filter((user: { is_selected: boolean }) => user.is_selected) // Check if user is selected
        .map((user: { uuid: string }) => user.uuid); // Get the names of the pre-selected users

      // Set the hierarchy and the selectedItems
      this.setState({
        count: data.count,
        next: data.next,
        hierarchy: data.results,
        selectedItems: preSelectedUsers, // Initialize selectedItems with pre-selected users
        isLoading: false, // Stop loading
        filteredUsers: data.results,
      });
    } catch (error) {
      console.error("Error fetching hierarchy:", error);
      this.setState({ isLoading: false }); // Ensure loading state is false on error
    }
  };

  handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = event.target.value.toLowerCase();
    this.setState({
      searchTerm: event.target.value,
      filteredUsers: this.state.hierarchy.filter(
        (state: any) =>
          this.getTranslationData(state, "name")
            ?.toLowerCase()
            .includes(searchTerm) ||
          this.getTranslationData(state, "department")
            ?.toLowerCase()
            .includes(searchTerm) ||
          state?.email.toLowerCase().includes(searchTerm)
      ),
    });
  };

  handleCategoryChange = (category: string) => {
    this.setState({ selectedCategory: category });
  };

  handleScrollLeft = () => {
    const container = this.categoryContainerRef.current;
    if (container) {
      const newScrollPosition = Math.max(this.state.scrollPosition - 100, 0); // Scroll left
      container.scrollTo({ left: newScrollPosition, behavior: "smooth" });
      this.setState({ scrollPosition: newScrollPosition });
    }
  };

  handleScrollRight = () => {
    const container = this.categoryContainerRef.current;
    if (container) {
      const maxScrollLeft = container.scrollWidth - container.clientWidth;
      const newScrollPosition = Math.min(
        this.state.scrollPosition + 100,
        maxScrollLeft
      ); // Scroll right
      container.scrollTo({ left: newScrollPosition, behavior: "smooth" });
      this.setState({ scrollPosition: newScrollPosition });
    }
  };

  handleItemClick = async (itemName: string) => {
    let selectedIds: any[] = [];
    this.setState((prevState) => {
      const isSelected = prevState.selectedItems.includes(itemName);
      selectedIds = isSelected
        ? prevState.selectedItems.filter((name) => name !== itemName) // Deselect item
        : [...prevState.selectedItems, itemName]; // Select item
      updateIds(selectedIds); // Call the updateIds function
      return {
        selectedItems: selectedIds,
      };
    });

    // Find the user that was clicked
    // const clickedUser = this.state.hierarchy
    //   .flatMap((department) => department.users)
    //   .find((user) => user.name === itemName);

    const updateIds = async (selectedIds: any) => {
      const clickedUser: any = this.state.hierarchy.some(
        (user: any) => user.uuid === itemName
      );

      // If user is found, call the createNodeUser API
      if (clickedUser) {
        try {
          console.log("API response:", selectedIds);
          await createNodeUser(this.props.selectedNodeId, selectedIds);
        } catch (error) {
          console.error("Error calling createNodeUser API:", error);
        }
      }
    };
  };

  getTranslationData(data: any, key: string) {
    const locale = this.props.locale || "en"; // Provide a default value for locale
    return data.translations?.[locale]?.[key];
  }
  handleResize = (e: any, data: any) => {
    this.setState({ containerWidth: data.size.width });
  };

  fetchMoreData = () => {
    console.log("more data");

    fetch(this.state.next, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) =>
        this.setState((prev) => ({
          hasMore: data.next !== null,
          next: data.next,
          hierarchy: [...prev.hierarchy, ...data.results],
          filteredUsers: [...prev.filteredUsers, ...data.results],
        }))
      );
  };
  getColumnsBasedOnWidth() {
    if (this.state.containerWidth > 800) {
      return 3;
    } else if (this.state.containerWidth > 600) {
      return 4;
    } else if (this.state.containerWidth <= 400) {
      return 12;
    } else {
      return 6;
    }
  }

  render() {
    const { show, onClose } = this.props;
    const { searchTerm, selectedItems, isLoading, selectedCategory } =
      this.state;

    // Render loading state
    if (isLoading) {
      return (
        <div className={`sidebar2 ${show ? "visible" : ""}`}>
          <div className="loading">Loading...</div>
        </div>
      );
    }

    // Prepare category labels
    const categoryLabels = ["All"];

    return (
      // <ClickAwayListener onClickAway={() => onClose()}>
      <ResizableBox
        className="z-[1000]"
        width={this.state.containerWidth}
        height={0}
        axis="x"
        resizeHandles={["w"]}
        onResizeStop={this.handleResize}
        onResize={this.handleResize}
        minConstraints={[300, 0]}
        maxConstraints={[1200, 0]}
        handle={
          <div
            style={{
              width: "5px",
              cursor: "ew-resize",
              // backgroundColor: "#007bff",
              height: "100vh",
              position: "absolute",
              top: 0,
              left: 0,
            }}
          />
        }
      >
        <div
          className={`sidebar2 ${show ? "visible" : ""}`}
          style={{ width: this.state.containerWidth }}
          id="scrollableDiv"
        >
          <InfiniteScroll
            dataLength={this.state.filteredUsers.length} //This is important field to render the next data
            next={this.fetchMoreData}
            hasMore={this.state.hasMore}
            loader={<h4>Loading...</h4>}
            endMessage={
              <p style={{ textAlign: "center" }}>
                <b>Yay! You have seen it all</b>
              </p>
            }
            scrollableTarget="scrollableDiv"
          >
            <div className="header flex flex-row justify-between">
              <span style={{ fontSize: "18px" }}>
                <FormattedMessage id="addUsertoWorkFlow"></FormattedMessage>
              </span>

              <IconButton size="small" onClick={onClose}>
                <Close />
              </IconButton>
            </div>

            <div className="search-bar">
              <input
                type="text"
                placeholder="Search...."
                value={searchTerm}
                onChange={this.handleSearchChange}
              />
            </div>

            {/* Category navigation with arrows */}
            <div className="category-navigation">
              <button
                className="arrow arrow-left"
                onClick={this.handleScrollLeft}
              >
                <AiOutlineLeft />
              </button>
              <div
                className="category-container"
                ref={this.categoryContainerRef}
              >
                {categoryLabels.map((label, index) => (
                  <button
                    key={index}
                    className={`category-button ${
                      selectedCategory === label ? "active" : ""
                    }`}
                    onClick={() => this.handleCategoryChange(label)}
                  >
                    {label}
                  </button>
                ))}
              </div>
              <button
                className="arrow arrow-right"
                onClick={this.handleScrollRight}
              >
                <AiOutlineRight />
              </button>
            </div>

            {/* Users display */}
            {/* <div className="items-grid"> */}

            <Grid container rowSpacing={1.5} columnSpacing={1.5}>
              {this.state.filteredUsers.map((user: any) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={this.getColumnsBasedOnWidth()}
                  key={user.uuid}
                  padding={1.5}
                >
                  <ButtonBase
                    sx={{
                      padding: 1.5,
                      width: "100%",
                      borderRadius: 2,
                      border: "1px solid #e0e0e0",
                      backgroundColor: "#fff",
                    }}
                    className={`item ${
                      selectedItems.includes(user.uuid) ? "selected" : ""
                    }`}
                    onClick={() => this.handleItemClick(user.uuid)}
                  >
                    <Stack alignItems="center" spacing={1} width="100%">
                      <Avatar
                        src={user.profile_img || ""}
                        sx={{
                          width: "70px",
                          height: "70px",
                          bgcolor: getProfileColor(user.email),
                          fontSize: "34px",
                        }}
                      >
                        {this.getTranslationData(user, "name")?.charAt(0)}
                      </Avatar>
                      <Stack direction="row" spacing={0.25} alignItems="center">
                        <Typography variant="h6">
                          {this.getTranslationData(user, "name")}
                        </Typography>
                        <Typography
                          color="text.secondary"
                          variant="subtitle1"
                          textAlign="center"
                        >
                          (Junior)
                        </Typography>{" "}
                      </Stack>
                      <Divider flexItem />
                    </Stack>
                    <Stack spacing={1} width="100%" marginTop={2}>
                      <CardDetail
                        title="Email"
                        icon={<EmailOutlined />}
                        value={user.email}
                      />
                      <CardDetail
                        title="Department"
                        icon={<FaRegBuilding />}
                        value={this.getTranslationData(user, "department")}
                      />
                    </Stack>
                  </ButtonBase>
                </Grid>
              ))}
            </Grid>
          </InfiniteScroll>
        </div>
        {/* </div> */}
      </ResizableBox>
      // </ClickAwayListener>
    );
  }
}

export default NodeUserList;

function CardDetail({
  title,
  value,
  icon,
}: {
  title: string;
  value: string;
  icon: any;
}) {
  return (
    <Stack direction="row" spacing={1} alignItems="center" width="100%">
      <Box
        sx={{
          "& svg": {
            height: "16px",
            width: "16px",
            color: "grey",
          },
        }}
      >
        {icon}
      </Box>
      <Stack width="80%" alignItems={"flex-start"} flex={1}>
        <Typography color="text.secondary" variant="subtitle2">
          {title}
        </Typography>

        <Typography
          variant="subtitle1"
          noWrap
          style={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            maxWidth: "100%",
          }}
        >
          {value}
        </Typography>
      </Stack>
    </Stack>
  );
}

function getProfileColor(input: string) {
  // Expanded list of 20 Google-like colors
  const colors = [
    "#4285F4",
    "#DB4437",
    "#0F9D58",
    "#F4B400",
    "#A142F4", // Primary Google colors
    "#FF6D00",
    "#46BDC6",
    "#2E7D32",
    "#C2185B",
    "#7B1FA2",
    "#E53935",
    "#8E24AA",
    "#3949AB",
    "#1E88E5",
    "#00ACC1",
    "#00897B",
    "#43A047",
    "#FDD835",
    "#FB8C00",
    "#F4511E",
    "#D81B60",
    "#5E35B1",
    "#039BE5",
    "#00ACC1",
    "#00838F",
    "#2E7D32",
    "#C0CA33",
    "#FFD600",
    "#FF7043",
    "#6D4C41",
  ];

  // Simple hash function
  function hashString(str: string) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return Math.abs(hash); // Ensure positive hash
  }

  // Get hash and map to color
  const hashValue = hashString(input);
  return colors[hashValue % colors.length];
}
