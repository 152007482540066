import ReactFlow, {
  Background,
  getBezierPath,
  MarkerType,
  MiniMap,
} from "reactflow";
import { useParams } from "react-router";
import NodeWrapper from "../../workflow V2/NodeWrapper";
import { useEffect, useMemo, useState } from "react";
import { listWorkFlowNodes } from "../../../apis/flowBuilder";
import WorkflowNode from "./components/workflowNode";
import CustomEdge from "./components/Edge";
import BranchNode from "./components/branchNode";
import { fetchAdminRequestDetails } from "../../../apis/administration";

function RequestDetail() {
  const { requestId } = useParams();

  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);


  useEffect(() => {
    getRequestDetails();
  }, []);

  const getRequestDetails = async () => {
    try {
      // const data: any = await listWorkFlowNodes(
      //   "c74e0e48-ad63-4e55-8117-05c6032e857e"
      // );
      const data: any = await fetchAdminRequestDetails(
        requestId
      );
      console.log(data)
      setNodes(data?.nodes);
      setEdges(data?.edges);
    } catch (error) {
      console.log(error);
    }
  };

  const nodeTypes = useMemo(
    () => ({
      WorkFlowNode: (props: any) => {
        return (
          <NodeWrapper>
            <WorkflowNode {...props} />
          </NodeWrapper>
        );
      },
      BranchNode: (props: any) => {
        return (
          <NodeWrapper>
            <BranchNode {...props} />
          </NodeWrapper>
        );
      },
    }),
    []
  );

  const defaultEdgeOptions = {
    type: "floating",
    markerEnd: {
      type: MarkerType.ArrowClosed,
      color: "#b1b1b7",
    },
  };

  const EdgeType: any = {
    AF: (props: any) => <CustomEdge {...props} type="AF" />,
  };

  return (
    <>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        fitView={true}
        nodeTypes={nodeTypes}
        snapToGrid
        defaultEdgeOptions={defaultEdgeOptions}
        edgeTypes={EdgeType}
      >
        <MiniMap />
        <Background gap={12} size={1} />
      </ReactFlow>
    </>
  );
}

export default RequestDetail;
