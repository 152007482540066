import { Avatar } from "@mui/material";
import { useEffect, useState } from "react";
import { BsPlus } from "react-icons/bs";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { fetchDeparments } from "../../../apis/administration";
import Chip from "../../../components/Chip/Chip";
import DataTable from "../../../components/DataTable/dataTable";
import { formattedDate } from "../../../utils/constants";
import CreateDepartment from "./createDepartment";

type ObjType = {
  id: number;
  uuid: string;
  name: string;
  description: string;
  remarks: string;
  icon: string | null;
  is_active: boolean;
  on_confirmation: boolean;
  created_at: string;
  updated_at: string;
  created_by: number;
  category: number;
};

const DepartmentAdministration = () => {
  const [dataList, setDataList] = useState<ObjType[]>([]);
  const [loader, setLoader] = useState(true);
  const [createCatOpen, setCreateCatOpen] = useState(false);

  const fetchList = async (pageNumber: number, pageSize: number) => {
    try {
      const res = await fetchDeparments(pageNumber, pageSize);
      console.log(res);
      setTotalCount(res.count);
      setDataList(res.results ?? []);
      setLoader(false);
    } catch (err) {
      setLoader(false);
    }
  };

  const columns = [
    {
      key: "name",
      label: <FormattedMessage id="department" />,
      render: (value: string, row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Avatar
              src={row.icon_url || ""}
              sx={{ width: 42, height: 42, bgcolor: "primary.main" }}
            >
              {value?.charAt(0)}
            </Avatar>
            <span className="text-sm font-medium text-gray-700 truncate">
              {value}
            </span>
          </div>
        );
      },
    },
    { key: "created_by", label: <FormattedMessage id="adminThCreatedBy" /> },
    {
      key: "created_at",
      label: <FormattedMessage id="adminThCreatedDate" />,
      render: (value: any) => formattedDate(value),
    },
    {
      key: "updated_at",
      label: <FormattedMessage id="adminThLastUpdated" />,
      render: (value: any) => formattedDate(value),
    },
    {
      key: "is_active",
      label: <FormattedMessage id="status" />,
      render: (value: any) => (
        <Chip
          type={value ? "info" : "error"}
          value={value ? "Active" : "In Active"}
        />
      ),
    },
  ];

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    fetchList(pageNumber, pageSize);
  }, [pageNumber, pageSize]);

  const handlePageChange = (page: number) => {
    setPageNumber(page);
  };

  const handlePageSizeChange = (size: number) => {
    setPageSize(size);
    setPageNumber(1); // Reset to the first page when page size changes
  };

  return (
    <div className="space-y-4 p-4">
      <div className="flex items-center justify-between border-b pb-4">
        <h1 className="text-2xl font-bold text-gray-900">
          <FormattedMessage id="headingDeparmentManagement" />
        </h1>
        <Link to="">
          <button
            className="flex items-center gap-2 px-4 py-2 text-white rounded-lg bg-primary"
            onClick={() => setCreateCatOpen(true)}
          >
            <BsPlus className="w-4 h-4" />
            <span>
              <FormattedMessage id="newDepartment" />
            </span>
          </button>
        </Link>
      </div>

      {createCatOpen && (
        <CreateDepartment
          open={createCatOpen}
          handleClose={() => setCreateCatOpen(false)}
          onSucess={() => fetchList(pageNumber, pageSize)}
        />
      )}
      <DataTable
        columns={columns}
        data={dataList && Array.isArray(dataList) ? dataList : []}
        loading={loader}
        pagination={true}
        pageSize={pageSize}
        pageNumber={pageNumber}
        totalCount={totalCount}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
      ></DataTable>
    </div>
  );
};

export default DepartmentAdministration;
