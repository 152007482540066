import { Close, Remove } from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonBase,
  ButtonGroup,
  Grow,
  IconButton,
  Popper,
  Slide,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useCallback, useState } from "react";
import { BsStars } from "react-icons/bs";
import { FaWpforms } from "react-icons/fa";
import { IoGitBranch } from "react-icons/io5";
import { useIntl } from "react-intl";
import { useOnSelectionChange } from "reactflow";
import DirectionBasedOnLanguage from "../../components/DirectionBasedOnLanguage";
import useTranslation from "../../hooks/useTranslation";
import "./aichatbox.css";

function AIChatBox() {
  const theme: any = useTheme();
  const [selectedTab, setSelectedTab] = useState("workflow");
  const { translate } = useTranslation();
  const [selectedNode, setSelectedNode] = useState<string>();
  const { locale } = useIntl();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => !prev);
  };
  // the passed handler has to be memoized, otherwise the hook will not work correctly
  const onChange = useCallback(({ nodes, edges }: any) => {
    setSelectedNode(nodes.map((node: any) => node.id)[0]);
  }, []);

  useOnSelectionChange({
    onChange,
  });

  return (
    <DirectionBasedOnLanguage language={locale}>
      <button onClick={handleClick} className="button-trigger-ai">
        <BsStars />
      </button>
      {/* {open && ( */}
      <Popper open={open} anchorEl={anchorEl} transition placement="bottom-end">
        {({ TransitionProps }) => (
          <Slide {...TransitionProps} direction="up">
            <div
              onClick={(e) => e.stopPropagation()}
              onDoubleClick={(e) => e.stopPropagation()}
              className="aicontainer"
            >
              <Box className="aiheader-container">
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  p={1.3}
                  borderBottom={`1px solid ${theme.palette.border.main}`}
                >
                  <Typography variant="caption">
                    {translate("generateWorkflow")}
                  </Typography>
                  <Stack direction="row" alignContent="center">
                    <IconButton size="small">
                      <Remove sx={{ height: 14, width: 14 }} />
                    </IconButton>
                    <IconButton size="small" onClick={() => setOpen(false)}>
                      <Close sx={{ height: 14, width: 14 }} />
                    </IconButton>
                  </Stack>
                </Stack>

                <Stack padding={1.3} spacing={2}>
                  <ButtonGroup
                    variant="outlined"
                    aria-label="Basic button group"
                    size="small"
                  >
                    <Button
                      startIcon={<IoGitBranch />}
                      onClick={() => setSelectedTab("workflow")}
                      variant={
                        selectedTab === "workflow" ? "contained" : "outlined"
                      }
                      disableElevation
                    >
                      {translate("workflow")}
                    </Button>
                    <Button
                      startIcon={<FaWpforms />}
                      disableElevation
                      onClick={() => setSelectedTab("form")}
                      variant={
                        selectedTab === "form" ? "contained" : "outlined"
                      }
                    >
                      {translate("form")}
                    </Button>
                  </ButtonGroup>
                  <Stack spacing={0.3}>
                    <textarea
                      className="ai-chat-area"
                      placeholder={`Create ${
                        selectedTab.charAt(0).toUpperCase() +
                        selectedTab.slice(1)
                      }...`}
                      rows={4}
                    />
                    {selectedTab === "form" && !selectedNode && (
                      <Typography variant="subtitle2" color="error">
                        {translate("selectNodeToCreateForm")}
                      </Typography>
                    )}
                  </Stack>
                  <ButtonBase
                    disabled={selectedTab === "form" && !selectedNode}
                    className="ai-generate-button"
                  >
                    <BsStars />
                    <Typography
                      variant="subtitle1"
                      color="white"
                      className="text"
                    >
                      {translate("generate")}
                    </Typography>
                  </ButtonBase>
                </Stack>
              </Box>
            </div>
          </Slide>
        )}
      </Popper>
      {/* )} */}
    </DirectionBasedOnLanguage>
  );
}

export default AIChatBox;
