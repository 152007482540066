import { AddOutlined, CloseOutlined } from "@mui/icons-material";
import { FC, useState } from "react";
import { FormattedMessage } from "react-intl";
import useTranslation from "../../../hooks/useTranslation";
import Dropdown from "../../Dropdown/Dropdown";
import CheckBox from "../components/CheckBox";
import { elements_type } from "../constants";
import {
  updateTranslationData,
  updateTranslationOptions,
} from "../formTranslations";
import InputField from "./InputField";
import DependentPopup from "../components/dependentPopup";

interface DropDownInterface {
  collapse: any;
  formElement: any;
  onDelete: () => void;
  onChange: (value: any, api_call?: boolean) => void;
  ar: { label?: string; options: { value: string; label: string }[] };
  en: { label?: string; options: { value: string; label: string }[] };
  name?: any;
  activeLanguage: any;
  formData: any;
}

const DropDown: FC<DropDownInterface> = ({
  collapse,
  formElement,
  onChange,
  activeLanguage,
  formData,
}) => {
  const [data, setData] = useState<{
    id: string;
    label: string;
    options: string[];
    required: boolean;
    show_all_stages: boolean;
    width: string;
    element_type: string;
    translate?: any;
    dependentDetails:any
  }>({
    element_type: elements_type.DROPDOWN,
    id: formElement?.id || "",
    label: formElement?.label || "",
    required: formElement?.required || false,
    options: formElement?.options || [""],
    show_all_stages: formElement?.show_all_stages || false,
    width: formElement?.width,
    translate: {
      en: { options: [{ label: "" }] },
      ar: { options: [{ label: "" }] },
      ...formElement.translate,
    },
    dependentDetails: formElement?.dependentDetails || {
      parentId: "",
      condition: "",
      value: "",
    },
  });
  // const [activeLanguage, setActiveLanguage] = useState<"en" | "ar">("en");
  const { translate } = useTranslation();

  const handleChange = (value: string, index: number, call_api?: boolean) => {
    const updatedData = updateTranslationOptions(
      data,
      activeLanguage,
      "edit",
      index,
      value
    );
    setData(updatedData);
    call_api && onChange(updatedData, true);
  };

  const addOption = () => {
    const updatedData = updateTranslationOptions(
      data,
      activeLanguage,
      "add",
      0,
      ""
    );
    setData(updatedData);
  };

  const removeOption = (index: number) => {
    const updatedData = updateTranslationOptions(
      data,
      activeLanguage,
      "delete",
      index,
      ""
    );
    setData(updatedData);
  };

  function updateData(
    name: string,
    value: boolean | string | any,
    call_api?: boolean
  ) {
    onChange(data, call_api);
  }

  function updateLabel(value: string, call_api?: boolean) {
    const updatedData = updateTranslationData(
      data,
      "label",
      elements_type.DROPDOWN,
      value,
      activeLanguage
    );
    setData(updatedData);
    call_api && onChange(updatedData, true);
  }

  return (
    <>
      <div className="p-4 bg-gray-50 ">
        {/* <h3 className="text-sm font-medium text-gray-700 mb-2">Preview</h3> */}
        <div className="bg-white p-4 rounded-md shadow-md h-[102px]">
          {/* <CheckBox
            label={data[activeLanguage].label}
            isChecked={data.required}
            onChange={(e: any) => {}}
          /> */}
          <Dropdown
            label={
              (data?.label || translate("labelTextLabel")) +
              (data.required ? " *" : "")
            }
            options={
              data?.translate?.[activeLanguage]?.options?.map((x: any) => ({
                label: typeof x === "object" ? x.label : x,
                value: typeof x === "object" ? x.label : x,
              })) ?? []
            }
            value=""
            name=""
            onChange={() => {}}
          />
        </div>
      </div>
      {collapse === formElement?.id && (
        <>
          {/* Form Content */}
          <div className="p-4">
            <InputField
              label={translate("labelTextLabel")}
              placeholder={translate("placeHolderLabel")}
              value={data?.translate[activeLanguage].label}
              onChange={(value) => updateLabel(value, false)}
              onBlur={(value) => updateLabel(value, true)}
            />

            <DependentPopup
              data={data}
              formData={formData}
              onChange={updateData}
              activeLanguage={activeLanguage}
            />
            {/* <InputField
              label="Name"
              value={data.name}
              placeholder="Name for dropdown"
              onChange={(value) =>
                setData({
                  ...data,
                  name: value,
                })
              }
            /> */}
            {/* <FormControlLabel
              control={<Checkbox />}
              label={"Required"}
              onChange={(e: any) =>
                setData((state) => ({
                  ...state,
                  required: e.target.checked,
                }))
              }
            /> */}
            <div className="mt-4">
              <h5 className="font-medium">
                <FormattedMessage id="optionsForDropdown"></FormattedMessage>
              </h5>
              {data?.translate[activeLanguage]?.options?.map(
                (option: any, index: any) => (
                  <div className="flex flex-col mt-4 gap-y-2" key={index}>
                    <div className="flex flex-col md:flex-row gap-2 items-end jusify-center w-full">
                      <InputField
                        label={translate("optionLabel")}
                        placeholder={translate("optionLabelPlaceholder")}
                        value={option.label}
                        onChange={(value) => handleChange(value, index)}
                        onBlur={(value) => updateData("options", value, true)}
                      />

                      {index === data?.options?.length - 1 ? (
                        // Add Button for the last option
                        <button
                          type="button"
                          onClick={addOption}
                          className="text-blue-700 hover:bg-blue-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full p-2.5 inline-flex items-center"
                        >
                          <AddOutlined />
                        </button>
                      ) : (
                        // Close Button for all other options
                        <button
                          type="button"
                          onClick={() => removeOption(index)}
                          className="text-red-700 hover:bg-red-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-full p-2.5 inline-flex items-center"
                        >
                          <CloseOutlined />
                        </button>
                      )}
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
          <div className="flex pl-4 pr-4 pb-4 justify-between">
            <CheckBox
              label={translate("requiredErrorMessage")}
              isChecked={data.required}
              onChange={(e: any) =>
                updateData("required", e.target.checked, true)
              }
            />
            <CheckBox
              label={translate("showAllStages")}
              isChecked={data.show_all_stages}
              onChange={(e) =>
                updateData("show_all_stages", e.target.checked, true)
              }
            />
          </div>
        </>
      )}
    </>
  );
};

export default DropDown;
