import { RemoveRedEyeOutlined } from "@mui/icons-material";
import { Avatar, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { BsPlus } from "react-icons/bs";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { listProcess } from "../../../apis/process";
import Chip from "../../../components/Chip/Chip";
import DataTable from "../../../components/DataTable/dataTable";
import useTranslation from "../../../hooks/useTranslation";
import "./ProcessList.css"; // Import the CSS file

interface Process {
  uuid: string;
  name: string;
  description: string;
  created_by: string;
  current_runs: number;
  status: string;
}

export function ProcessList() {
  const { translate } = useTranslation();
  const { locale } = useIntl();
  const [processes, setProcesses] = useState<Process[]>([]);
  const [loader, setLoader] = useState(false);

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);

  const handlePageChange = (page: number) => {
    setPageNumber(page);
  };

  const handlePageSizeChange = (size: number) => {
    setPageSize(size);
    setPageNumber(1); // Reset to the first page when page size changes
  };

  useEffect(() => {
    (async () => {
      setLoader(true);
      try {
        const data = await listProcess(null, null, { pageNumber, pageSize });
        setProcesses(data.results);
        setTotalCount(data.count);
        setLoader(false);
      } catch (error) {
        setLoader(false);
        console.error("Error fetching processes:", error);
      }
    })();
  }, [pageNumber, pageSize]);

  function getTextBylang(process: any, key: string) {
    console.log(key, process);
    if (
      process.translations &&
      process.translations[locale] &&
      process.translations[locale][key]
    ) {
      return process.translations?.[locale]?.[key];
    }
    return process[key];
  }

  const columns = [
    {
      key: "name",
      label: <FormattedMessage id="adminThProcessName" />,
      render: (value: string, row: any) => {
        const name = getTextBylang(row, "name");
        return (
          <div className="flex items-center gap-3">
            <Avatar
              src={row.icon || ""}
              sx={{ width: 42, height: 42, bgcolor: "primary.main" }}
            >
              {name?.charAt(0)}
            </Avatar>
            <span className="text-sm font-medium text-gray-700 truncate">
              {name}
            </span>
          </div>
        );
      },
    },
    {
      key: "category",
      label: <FormattedMessage id="adminThCategory" />,
      render: (value: any, row: any) => (
        <Chip
          type="info"
          value={row.category?.[locale]?.name ?? translate("notSpecified")}
        />
      ),
    },
    {
      key: "description",
      label: <FormattedMessage id="description" />,
      render: (value: any, row: any) => {
        return (
          <div className="truncate">
            {getTextBylang(row, "description").length > 50
              ? `${getTextBylang(row, "description").substring(0, 50)}...`
              : getTextBylang(row, "description")}
          </div>
        );
      },
    },
    {
      key: "created_by",
      label: <FormattedMessage id="createdBy" />,
    },
    {
      key: "status",
      label: <FormattedMessage id="status" />,
      render: (value: any) => <Chip type="info" value={translate("active")} />,
    },
    {
      key: "action",
      label: <FormattedMessage id="adminThAction" />,
      render: (_: any, row: any) => (
        <Link to={`/process-list-v2/flow-builder/${row?.uuid}`}>
          <Button
            startIcon={<RemoveRedEyeOutlined />}
            className="rtl:gap-[10px]"
            sx={{ textTransform: "capitalize" }}
          >
            <FormattedMessage id="view" />
          </Button>
        </Link>
      ),
    },
  ];

  return (
    <div className="space-y-4 p-4">
      <div className="border-b pb-4">
        <div className="flex flex-row gap-2 justify-between">
          <h1 className="text-2xl font-bold text-gray-900">
            <FormattedMessage id="headingFlowBuilder"></FormattedMessage>
          </h1>
          <Link to="/process-list/create-process">
            <button
              className="flex items-center gap-2 px-4 py-2 text-white rounded-lg bg-primary"
              // style={{ backgroundColor: "#0060AB" }
              // onClick={() => console.log("Add new process")}
            >
              <BsPlus className="w-4 h-4" />
              <span>
                <FormattedMessage id="newAutomationFlow" />
              </span>
            </button>
            {/* <Button variant="contained" className="ml-4">
              + <FormattedMessage id="newAutomationFlow" />
            </Button> */}
          </Link>
        </div>
      </div>
      <DataTable
        columns={columns}
        loading={loader}
        data={processes}
        pagination={true}
        pageSize={pageSize}
        pageNumber={pageNumber}
        totalCount={totalCount}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
      ></DataTable>
    </div>
  );
}

export default ProcessList;
