import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import useTranslation from "../../../hooks/useTranslation";

interface FileUploadProps {
  name: string;
  label?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  prepend?: React.ReactNode;
  value?: File | null;
  disabled?: boolean;
  accept?: string;
}

const FileUpload: React.FC<FileUploadProps> = ({
  name,
  label,
  onChange,
  prepend,
  value,
  disabled = false,
  accept = "*",
  ...props
}) => {
  const [fileName, setFileName] = useState<string | null>(
    value ? value.name : null
  );
  const { translate } = useTranslation();

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // const file = e.target.files ? e.target.files[0] : null;
    // setFileName(file ? file.name : null);
    onChange(e); // Calling the onChange function passed as a prop
  };

  useEffect(() => {
    setFileName(value?.name ?? "");
  }, [value]);
  return (
    <div className="flex flex-col w-full">
      {label && (
        <Typography variant="subtitle1" textTransform={"capitalize"} mb={1}>
          {label}{" "}
        </Typography>
      )}
      <div
        className={`shadow appearance-none border rounded w-full overflow-hidden flex items-center ${
          prepend ? "pl-2" : ""
        }`}
      >
        {prepend && <span className="mr-2">{prepend}</span>}
        <input
          {...props}
          accept="*"
          disabled={disabled}
          type="file"
          id={name}
          name={name}
          onChange={handleFileChange}
          className="w-full p-2 text-base text-gray-700 bg-white focus:outline-none"
        />
      </div>
      {fileName && (
        <div className="mt-2 text-sm text-gray-600 mb-1">
          <strong>{translate("selectedFile")}:</strong> {fileName}
        </div>
      )}
    </div>
  );
};

export default FileUpload;
