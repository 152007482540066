import "./login.css";
import { useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { login } from "../../apis/loginsignup";
import { useNavigate } from "react-router";
import toast from "react-hot-toast";
import { Loading } from "../../components/Loading/Loading";

const loginSchema = Yup.object({
  username: Yup.string().email().required(),
  password: Yup.string().required(),
});

export function Login() {
  const [checkbox, setCheckBox] = useState(false);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  async function handleSubmit(values: any) {
    setIsLoading(true);
    try {
      const data = await login(values);
      localStorage.setItem("token", data.token);
      localStorage.setItem("user_details" , JSON.stringify(data?.user_details?.translations))
      localStorage.setItem("profile_img", data?.user_details?.profile_img);
      console.log(data);
       window.location.href = "/home";
    } catch (err: any) {
      console.log("ERRRR:", err);
      toast.error("Incorrect Username Or Password");
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      <Loading open={isLoading} />
      <div className="login justify-center flex items-center">
        <div className="flex flex-col w-96 rounded-md h-fit p-11 text-center gap-2">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
            Log In
          </h2>
          <div className="flex border justify-center items-center p-1 google_button">
            <svg
              role="graphics-symbol"
              viewBox="0 0 20 20"
              className="googleLogo"
              style={{
                width: "14px",
                height: "14px",
                display: "block",
                fill: "inherit",
                flexShrink: 0,
                marginRight: "6px",
              }}
            >
              <g>
                <path
                  d="M19.9996 10.2297C19.9996 9.54995 19.9434 8.8665 19.8234 8.19775H10.2002V12.0486H15.711C15.4823 13.2905 14.7475 14.3892 13.6716 15.0873V17.586H16.9593C18.89 15.8443 19.9996 13.2722 19.9996 10.2297Z"
                  fill="#4285F4"
                ></path>
                <path
                  d="M10.2002 20.0003C12.9518 20.0003 15.2723 19.1147 16.963 17.5862L13.6753 15.0875C12.7606 15.6975 11.5797 16.0429 10.2039 16.0429C7.54224 16.0429 5.28544 14.2828 4.4757 11.9165H1.08301V14.4923C2.81497 17.8691 6.34261 20.0003 10.2002 20.0003Z"
                  fill="#34A853"
                ></path>
                <path
                  d="M4.47227 11.9163C4.04491 10.6743 4.04491 9.32947 4.47227 8.0875V5.51172H1.08333C-0.363715 8.33737 -0.363715 11.6664 1.08333 14.4921L4.47227 11.9163Z"
                  fill="#FBBC04"
                ></path>
                <path
                  d="M10.2002 3.95756C11.6547 3.93552 13.0605 4.47198 14.1139 5.45674L17.0268 2.60169C15.1824 0.904099 12.7344 -0.0292099 10.2002 0.000185607C6.34261 0.000185607 2.81497 2.13136 1.08301 5.51185L4.47195 8.08764C5.27795 5.71762 7.53849 3.95756 10.2002 3.95756Z"
                  fill="#EA4335"
                ></path>
              </g>
            </svg>
            Continue with Google
          </div>

          <div className="flex border justify-center items-center p-1 google_button">
            <svg
              role="graphics-symbol"
              viewBox="0 0 170 170"
              className="appleLogo"
              style={{
                width: "16px",
                height: "16px",
                display: "block",
                fill: "inherit",
                flexShrink: 0,
                marginRight: "6px",
                marginBottom: "2px",
              }}
            >
              <path d="m150.37 130.25c-2.45 5.66-5.35 10.87-8.71 15.66-4.58 6.53-8.33 11.05-11.22 13.56-4.48 4.12-9.28 6.23-14.42 6.35-3.69 0-8.14-1.05-13.32-3.18-5.197-2.12-9.973-3.17-14.34-3.17-4.58 0-9.492 1.05-14.746 3.17-5.262 2.13-9.501 3.24-12.742 3.35-4.929 0.21-9.842-1.96-14.746-6.52-3.13-2.73-7.045-7.41-11.735-14.04-5.032-7.08-9.169-15.29-12.41-24.65-3.471-10.11-5.211-19.9-5.211-29.378 0-10.857 2.346-20.221 7.045-28.068 3.693-6.303 8.606-11.275 14.755-14.925s12.793-5.51 19.948-5.629c3.915 0 9.049 1.211 15.429 3.591 6.362 2.388 10.447 3.599 12.238 3.599 1.339 0 5.877-1.416 13.57-4.239 7.275-2.618 13.415-3.702 18.445-3.275 13.63 1.1 23.87 6.473 30.68 16.153-12.19 7.386-18.22 17.731-18.1 31.002 0.11 10.337 3.86 18.939 11.23 25.769 3.34 3.17 7.07 5.62 11.22 7.36-0.9 2.61-1.85 5.11-2.86 7.51zm-31.26-123.01c0 8.1021-2.96 15.667-8.86 22.669-7.12 8.324-15.732 13.134-25.071 12.375-0.119-0.972-0.188-1.995-0.188-3.07 0-7.778 3.386-16.102 9.399-22.908 3.002-3.446 6.82-6.3113 11.45-8.597 4.62-2.2516 8.99-3.4968 13.1-3.71 0.12 1.0831 0.17 2.1663 0.17 3.2409z"></path>
            </svg>
            <div>Continue with Apple</div>
          </div>
          <div className="flex border justify-center items-center p-1 google_button">
            <svg
              style={{
                marginRight: "6px",
              }}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 32 32"
              width="16"
              height="16"
            >
              <path d="M0 0h15.206v15.206H0z" fill="#f25022" />
              <path d="M16.794 0H32v15.206H16.794z" fill="#7fba00" />
              <path d="M0 16.794h15.206V32H0z" fill="#00a4ef" />
              <path d="M16.794 16.794H32V32H16.794z" fill="#ffb900" />
            </svg>

            <div>Continue with Microsoft</div>
          </div>

          <br />

          <hr />
          {/* @ts-ignore */}
          <Formik
            initialValues={{ username: "", password: "" }}
            validationSchema={loginSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched, handleBlur, handleChange }) => (
              <Form>
                <div className="email_input">
                  <label>Email</label>
                  <input
                    type="text"
                    name="username"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Enter your email address..."
                    className={`border border-gray-400 rounded-md px-2 py-2 textbox ${
                      errors.username && touched.username && "error"
                    }`}
                  />

                  <label>Password</label>
                  <input
                    type={checkbox ? "text" : "password"}
                    name="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Enter your password..."
                    className={`border border-gray-400 rounded-md px-2 py-2 textbox ${
                      errors.password && touched.password && "error"
                    }`}
                  />

                  <div className="flex w-full gap-1 my-1">
                    <input
                      checked={checkbox}
                      onChange={(e) => setCheckBox(e.target.checked)}
                      id="checkbox"
                      type="checkbox"
                    />
                    <label htmlFor="checkbox">Show Password</label>
                  </div>
                </div>

                <div className="flex justify-center login_btn">
                  <button
                    type="submit"
                    className="bg-blue-700 text-white rounded-md py-2 "
                  >
                    Continue with email
                  </button>
                </div>
              </Form>
            )}
          </Formik>

          <div className="flex flex-col mt-8">
            <p className="support_links underline">Forgot password</p>
            <p className="support_links">
              You can also{" "}
              <span className="underline">continue with SAML SSO</span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
