// @ts-nocheck
import { ArrowDownward } from "@mui/icons-material";
import { Button, CircularProgress, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import FileViewerr from "react-file-viewer";
import ErrorBoundary from "../ErrorBoundary";
import DocViewer, {
  PDFRenderer,
  DocViewerRenderers,
  PNGRenderer,
} from "@cyntler/react-doc-viewer";
import ExcelViewerComponent from "./ExcelViewer";
import NoPreview from "../noPreview/noPreview";

const fileUrls = [
  {
    uri: "https://processflow-media.s3.ap-south-1.amazonaws.com/Screenshot+(101).png",
  },
  {
    uri: "https://processflow-media.s3.ap-south-1.amazonaws.com/Binary_Search_1.pdf",
  },
  {
    uri: "https://processflow-media.s3.ap-south-1.amazonaws.com/cars24-car-price-clean2.csv",
  },
  {
    uri: "https://processflow-media.s3.ap-south-1.amazonaws.com/CS7200-Spring-2022-Assignment-2.docx",
  },
];

function FileViewer({ filepath, height }) {
  const [type, setType] = useState("");
  const [loading, setLoading] = useState(false);

  function Error(e) {
    console.log("error", e);
  }

  useEffect(() => {
    const fileType = filepath?.split(".")?.pop() ?? "";
    setType(fileType);
    setLoading(true);
  }, [filepath]);

  return (
    <ErrorBoundary
      key={filepath + type}
      fallback={
        <Stack
          spacing={1}
          alignItems="center"
          height="100%"
          justifyContent="center"
        >
          <Typography variant="h6">No preview available</Typography>
          <a
            href={filepath}
            style={{ color: "inherit", textDecoration: "none" }}
          >
            <Button
              variant="contained"
              disableElevation
              startIcon={<ArrowDownward />}
              size="small"
            >
              Download
            </Button>
          </a>
        </Stack>
      }
    >
      {(() => {
        switch (type) {
          // case "docx":
          // return (
          //   <DocViewer
          //   pluginRenderers={DocViewerRenderers}
          //   documents={[{ uri: filepath }]}
          //   />
          // );

          case "pdf":
          case "docx":
            return (
              <>
                {
                  loading && <></>
                  // <Stack alignItems="center" height={"100%"} justifyContent="center">
                  // <CircularProgress />
                  // </Stack>
                }
                <FileViewerr
                  key={filepath}
                  fileType={type}
                  filePath={filepath}
                  errorComponent={<></>}
                  onError={(e) => {
                    console.log(e);
                  }}
                />
              </>
            );
          case "xlsx":
          case "xls":
          case "csv":
            return <ExcelViewerComponent fileUrl={filepath} />;
          case "png":
          case "jpeg":
          case "jpg":
          case "gif":
            return (
              <img src={filepath} alt={"image"} height="100%" width="100%" />
            );
          default:
            return <NoPreview />;
        }
      })()}
    </ErrorBoundary>
  );
}
export default FileViewer;
