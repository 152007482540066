import { Add } from "@mui/icons-material";
import { Button, Stack } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router";
import * as Yup from "yup";
import {
  createProcess,
  listCategories,
  listLanguages,
} from "../../../apis/process";
import DialogCustomized from "../../../components/Dialog/DialogCustomized";
import Dropdown from "../../../components/Dropdown/Dropdown";
import FileUpload from "../../../components/FormElements/components/FileUpload";
import InputField from "../../../components/FormElements/newcompnents/InputField";
import TranslationSummary from "../../../containers/summary/TranslationSummary";
import useTranslation from "../../../hooks/useTranslation";
import {
  fetchCategoriesHome,
  fetchDeparmentsHome,
} from "../../../apis/administration";

// const fieldLabelMap: any = {
//   name: {
//     ar: {
//       label: "",
//       helperText: "",
//       placeholder: "أدخل الاسم",
//     },
//     SPANISH: { label: "", helperText: "", placeholder: "Ingrese el nombre" },
//   },
//   description: {
//     ar: { label: "", helperText: "", placeholder: "أدخل الوصف" },
//     SPANISH: {
//       label: "",
//       helperText: "",
//       placeholder: "Ingrese la descripción",
//     },
//   },
//   remarks: {
//     ar: { label: "", helperText: "", placeholder: "أدخل الملاحظات" },
//     SPANISH: {
//       label: "",
//       helperText: "",
//       placeholder: "Ingrese las observaciones",
//     },
//   },
// };

export function ProcessCreate() {
  const [categories, setCategories] = useState<Array<any>>([]);
  const [departments, setDepartments] = useState<Array<any>>([]);
  const [languages, setLanguages] = useState<Array<any>>([]);

  const { locale } = useIntl();
  // const [languagePreference] = useState<Record<string, string[]>>({});
  const navigate = useNavigate();
  const [isEdit, setIsEdit] = useState(true);
  const [formData, setFormData] = useState({});
  const [submitLoader, setSubmitLoader] = useState(false);

  const formik = useFormik<any>({
    initialValues: {
      language: locale,
      name: "",
      code: "",
      description: "",
      remarks: "",
      category: "",
      descriptionAr: "",
      nameAr: "",
      remarksAr: "",
      descriptionSp: "",
      nameSp: "",
      remarksSp: "",
      department: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      language: Yup.string().required("Language is required"),
      category: Yup.string().required("Category is required"),
      department: Yup.string().required("Department is required"),
    }),
    onSubmit: async (values) => {
      const reqBody = {
        language: values.language,
        name: values.name,
        description: values.description,
        remarks: values.remarks,
        code: values.code,
        created_by: 1,
        category: values?.category,
        department: values?.department,
      };

      setFormData((state) => ({ ...state, [values.language]: reqBody }));
      setIsEdit(false);
    },
  });

  const { translate } = useTranslation();

  useEffect(() => {
    getDepartmentList();
    getLanguages();
  }, []);

  const getDepartmentList = async () => {
    try {
      const data = await fetchDeparmentsHome();
      console.log(data.results);
      setDepartments(data.results);
    } catch (error) {
      console.log(error);
    }
  };

  const getCategoriesList = async (department: string) => {
    try {
      const data = await fetchCategoriesHome(department);
      setCategories(data.results);
    } catch (error) {
      console.log(error);
    }
  };
  const getLanguages = async () => {
    try {
      const data = await listLanguages();
      setLanguages(data);
    } catch (error) {
      console.log(error);
    }
  };

  async function createProccess() {
    setSubmitLoader(true);
    try {
      const reqBody = {
        // @ts-ignore
        ...formData.en,
        icon: file?.name ? { value: base64String, name: file?.name } : null,
        translations: formData,
      };
      const res = await createProcess(reqBody);
      if (res) {
        navigate(`/process-list-v2/flow-builder/${res.uuid}`);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setSubmitLoader(false);
    }
  }

  const [file, setFile] = useState<any>(null);
  const [base64String, setBase64String] = useState<string | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setBase64String(reader.result as string);
      };
    }
    setFile(file);
  };

  console.log(categories);

  return (
    <div className="flex items-center justify-center overflow-y-auto h-full">
      {/* <IntlProvider locale={formik.values?.language} messages={safeLocale}> */}
      <form onSubmit={formik.handleSubmit} className="h-full">
        <DialogCustomized
          title={translate("createNewProcess")}
          handleClose={() => {
            navigate("/process-list-v2");
          }}
          content={
            !isEdit ? (
              <Stack spacing={1}>
                {Object.values(formData).map((form: any) => {
                  return (
                    <TranslationSummary
                      key={form.language}
                      language={
                        languages.find((lang) => lang.code === form.language)
                          ?.name
                      }
                      handleEdit={() => {
                        formik.setValues({
                          language: form.language,
                          name: form.name,
                          code: form.code,
                          description: form.description,
                          remarks: form.remarks,
                          category: form.category,
                        });
                        setIsEdit(true);
                      }}
                      keys={[
                        { label: "name", value: "name" },
                        { label: "category", value: "categoryLabel" },
                        { label: "description", value: "description" },
                        { label: "remarks", value: "remarks" },
                      ]}
                      languageConfig={{
                        ...form,
                        categoryLabel: categories.find(
                          (cat) => cat.uuid === form.category
                        )?.name,
                      }}
                    />
                  );
                })}

                <Stack alignItems="flex-start" mt={1}>
                  <FileUpload
                    value={file}
                    accept="image/*"
                    onChange={handleFileChange}
                    name="logoupload"
                    label="Logo"
                  />
                </Stack>
                <Stack alignItems="flex-start">
                  <Button
                    startIcon={<Add />}
                    onClick={() => {
                      formik.resetForm();
                      formik.setValues({ language: "" });
                      setIsEdit(true);
                    }}
                  >
                    <FormattedMessage id="additionalLanguage"></FormattedMessage>
                  </Button>
                </Stack>
              </Stack>
            ) : (
              <div className="flex flex-col justify-between items-center h-full">
                <div style={{ width: "100%" }} className="flex flex-col gap-4">
                  <div className="flex flex-col items-start justify-center">
                    <Dropdown
                      label={translate("language")}
                      name="language"
                      options={languages}
                      onChange={(e) => {
                        formik.handleChange(e);
                      }}
                      labelKey="name"
                      valueKey="code"
                      value={formik.values.language}
                      error={
                        formik.touched.language &&
                        Boolean(formik.errors.language)
                      }
                      helperText={
                        formik.touched.language
                          ? (formik.errors.language as string)
                          : ""
                      }
                    />
                  </div>
                  <div className="flex flex-col items-start justify-center">
                    <InputField
                      name="name"
                      placeholder={translate("enterName")}
                      label={<FormattedMessage id="name" />}
                      value={formik.values.name}
                      onChange={(_, e: any) => formik.handleChange(e)}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={
                        formik.touched.name
                          ? (formik.errors.name as string)
                          : ""
                      }
                    />
                  </div>

                  <div className="flex flex-col items-start justify-center">
                    <Dropdown
                      label={<FormattedMessage id="department" />}
                      name="department"
                      options={
                        departments?.map((item: any) => ({
                          label: item?.translations?.[locale]?.department,
                          value: item.uuid,
                        })) ?? []
                      }
                      onChange={(e) => {
                        formik.handleChange(e);
                        formik.setFieldValue("category", "");
                        getCategoriesList(e.target.value);
                      }}
                      // labelKey="name"
                      // valueKey="uuid"
                      error={
                        formik.touched.category &&
                        Boolean(formik.errors.category)
                      }
                      helperText={
                        formik.touched.category
                          ? (formik.errors.category as string)
                          : ""
                      }
                    />
                  </div>

                  <div className="flex flex-col items-start justify-center">
                    <Dropdown
                      value={formik?.values?.category}
                      label={<FormattedMessage id="category" />}
                      name="category"
                      options={
                        categories?.map((item: any) => ({
                          label: item?.translations?.[locale]?.category,
                          value: item.uuid,
                        })) ?? []
                      }
                      onChange={(e) => {
                        formik.handleChange(e);
                      }}
                      error={
                        formik.touched.category &&
                        Boolean(formik.errors.category)
                      }
                      helperText={
                        formik.touched.category
                          ? (formik.errors.category as string)
                          : ""
                      }
                      disabled={!formik?.values?.department}
                    />
                  </div>

                  <div className="flex flex-col items-start justify-center">
                    <InputField
                      name="description"
                      placeholder={translate("enterDescription")}
                      value={formik.values.description}
                      label={<FormattedMessage id="description" />}
                      onChange={(_, e: any) => formik.handleChange(e)}
                      error={
                        formik.touched.description &&
                        Boolean(formik.errors.description)
                      }
                      helperText={
                        formik.touched.description
                          ? (formik.errors.description as string)
                          : ""
                      }
                    />
                  </div>
                  {/* {renderLanguageFields("description")} */}
                  <div className="flex flex-col items-start justify-center">
                    <InputField
                      name="remarks"
                      placeholder={translate("enterRemarks")}
                      value={formik.values.remarks}
                      label={<FormattedMessage id="remarks" />}
                      onChange={(_, e: any) => formik.handleChange(e)}
                      error={
                        formik.touched.remarks && Boolean(formik.errors.remarks)
                      }
                      helperText={
                        formik.touched.remarks
                          ? (formik.errors.remarks as string)
                          : ""
                      }
                    />
                  </div>
                </div>

                <Stack
                  justifyContent="flex-end"
                  width={"100%"}
                  alignItems="flex-end"
                  onClick={() => formik.handleSubmit()}
                  paddingY={1}
                >
                  <Button variant="contained" size="small" disableElevation>
                    <FormattedMessage id="continue" />
                  </Button>
                </Stack>
              </div>
            )
          }
          open={true}
          actions={
            !isEdit && (
              <Stack direction="row" justifyContent="flex-end" spacing={2}>
                <Button
                  onClick={() => navigate("/process-list-v2")}
                  size="small"
                  sx={{ textTransform: "none" }}
                >
                  Cancel
                </Button>
                <Button
                  fullWidth
                  size="small"
                  // type="submit"
                  variant="contained"
                  disableElevation
                  sx={{ textTransform: "none" }}
                  onClick={createProccess}
                  disabled={submitLoader}
                >
                  Create Process
                </Button>
              </Stack>
            )
          }
        />
      </form>
      {/* </IntlProvider> */}
    </div>
  );
}

export default ProcessCreate;
