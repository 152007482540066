import React from "react"
import { Navigate } from "react-router";


export default function CheckLogin({children} : {children : JSX.Element}){
    const token = localStorage.getItem("token");

    if(token){
        return <Navigate to="/process-list-v2"/>
    }
    return children
}