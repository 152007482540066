import { useEffect, useState } from "react";
import { IntlProvider } from "react-intl";
import MainLayout from "./layouts/MainLayout";

import { ThemeProvider } from "@mui/material";
import { Toaster } from "react-hot-toast";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { ReactFlowProvider } from "reactflow";
import ProtectedRoutes from "./components/protectedRoute/ProtectedRoute";
import CheckLogin from "./components/protectedRoute/checkloggin";
import { Herirarchy } from "./pages/heirarchy/heirarchy";
import Home from "./pages/home";
import { Inbox } from "./pages/inbox/inbox";
import { Login } from "./pages/login/Login";
import ProcessCreate from "./pages/process/processCreate/ProcessCreate";
import ProcessList from "./pages/process/processList/ProcessList";
import ProcessListV2 from "./pages/process/processList V2/ProcessList";
import { Signup } from "./pages/signup/Signup";
import { WorkFlow } from "./pages/workflow/Workflow";
import WorkFlowV2 from "./pages/workflow V2/Workflow";
import theme from "./theme";
import GlobalContextProvider from "./context/GlobalContextProvider";
import Dashboards from "./pages/dashboard/dashboards/dashboards";
import enMessages from "./locales/en";
import arMessages from "./locales/ar";
import ProcessDashboard from "./pages/dashboard/process";
import ProcessAdministration from "./pages/administration/process/processTable";
import RequestAdministration from "./pages/administration/request/requestTable";
import UserGroupAdministration from "./pages/administration/user-groups/userGroups";
import CategoryAdministration from "./pages/administration/category/category";
import RequestDetail from "./pages/administration/request/requestDetail";
import DepartmentAdministration from "./pages/administration/department/department";
import OrganizationAdministration from "./pages/administration/organization/organization";
import ProcessVersionDetails from "./pages/administration/process/versionDetail";
import StaffAdministration from "./pages/administration/users/users";
import CascadingParentTable from "./pages/administration/cascading-parent-tables/cascadingTable";
import CascadingDependentTables from "./pages/administration/cascading-parent-tables/cascadingDependentTables";
import CascadingColumns from "./pages/administration/cascading-parent-tables/cascadingColumns";

function App() {
  const [locale, setLocale] = useState("en");
  const [messages, setMessages] = useState<Record<string, any>>({
    en: enMessages,
    ar: arMessages,
  });

  console.log(locale);

  useEffect(() => {
    setLocale(localStorage.getItem("locale") ?? "en");
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <GlobalContextProvider>
        <IntlProvider locale={locale} messages={messages?.[locale] || {}}>
          <Toaster />
          <BrowserRouter>
            <ReactFlowProvider>
              <Routes>
                <Route path="" element={<Navigate to="/login" />} />
                <Route
                  path="/login"
                  element={
                    <CheckLogin>
                      <Login />
                    </CheckLogin>
                  }
                />
                <Route path="/signup" element={<Signup />} />

                <>
                  <Route
                    path=""
                    element={
                      <ProtectedRoutes>
                        <MainLayout
                          locale={locale}
                          handleLocaleChange={(updatedLocale) => {
                            setLocale(updatedLocale);
                            localStorage.setItem("locale", updatedLocale);
                          }}
                        />
                      </ProtectedRoutes>
                    }
                  >
                    {/* <Route path="/unnoted" element={<WorkLayout />} /> */}
                    <Route path="/home" element={<Home />} />
                    <Route path="/process-list" element={<ProcessList />} />
                    <Route
                      path="/process-list/flow-builder/:processId"
                      element={<WorkFlow />}
                    />
                    <Route
                      path="/process-list-v2"
                      element={<ProcessListV2 />}
                    />
                    <Route
                      path="/process-list-v2/flow-builder/:processId"
                      element={<WorkFlowV2 />}
                    />
                    <Route
                      path="/process-list/create-process"
                      element={<ProcessCreate />}
                    />
                    <Route path="/inbox" element={<Inbox />} />
                    <Route path="/heirarchy" element={<Herirarchy />} />
                    <Route
                      path="*"
                      element={
                        <div
                          className="flex items-center justify-center"
                          style={{
                            height: "calc(100vh - 64px)",
                          }}
                        >
                          <h1> 404 Not found </h1>
                        </div>
                      }
                    />
                    <Route
                      path="/inbox/inprogress"
                      element={<Inbox category="inprogress" />}
                    />
                    <Route
                      path="/inbox/completed"
                      element={<Inbox category="completed" />}
                    />
                    <Route path="/dashboard/dashboards" element={<></>} />
                    <Route
                      path="/dashboard/chart-builder"
                      element={<Dashboards />}
                    />
                    <Route
                      path="/dashboard/process-dashboard"
                      element={<ProcessDashboard />}
                    />
                    <Route
                      path="/administration/processes"
                      element={<ProcessAdministration />}
                    />
                     <Route
                      path="/administration/processes/versions/:processId"
                      element={<ProcessVersionDetails />}
                    />
                    <Route
                      path="/administration/requests"
                      element={<RequestAdministration />}
                    />
                    <Route
                      path="/administration/requests/:requestId"
                      element={<RequestDetail />}
                    />
                    <Route
                      path="/administration/categories"
                      element={<CategoryAdministration />}
                    />
                    <Route
                      path="/administration/user-groups"
                      element={<UserGroupAdministration />}
                    />
                    <Route
                      path="/administration/departments"
                      element={<DepartmentAdministration />}
                    />
                    <Route
                      path="/administration/organization"
                      element={<OrganizationAdministration />}
                    />
                    <Route
                      path="/administration/user-list"
                      element={<StaffAdministration />}
                    />
                    <Route
                      path="/administration/parent-tables"
                      element={<CascadingParentTable />}
                    />
                     <Route
                      path="/administration/parent-tables/dependent-tables/:tableId"
                      element={<CascadingDependentTables />}
                    />
                     <Route
                      path="/administration/parent-tables/column-values/:tableId"
                      element={<CascadingColumns />}
                    />
                  </Route>
                </>
              </Routes>
            </ReactFlowProvider>
            {/* <MainLayout
          locale={locale}
          handleLocaleChange={(updatedLocale) => setLocale(updatedLocale)}
          >
          <ReactFlowProvider>
          <Routes>
          <Route path="/home" element={<WorkLayout />} />
          <Route path="/process-list" element={<ProcessList />} />
          <Route
          path="/process-list/flow-builder/:processId"
          element={<WorkFlow />}
          />
          <Route
              path="/process-list/create-process"
              element={<ProcessCreate />}
              />
              <Route path="/inbox" element={<Inbox />} />
              <Route path="/heirarchy" element={<Herirarchy />} />
              </Routes>
              </ReactFlowProvider>
            </MainLayout> */}
          </BrowserRouter>
        </IntlProvider>
      </GlobalContextProvider>
    </ThemeProvider>
  );
}

export default App;
