const token = localStorage.getItem("token");
export const BASE_URL = "https://godfather123.pythonanywhere.com";
export const AUTH_TOKEN = `Token ${token}`;
export const ORGANIZATION_HEIRARCHY = `${BASE_URL}/organization/hierarchy/`;
export const ORGANIZATION_HEIRARCHY_EDGE = `${BASE_URL}/organization/edge/`;
export const WORK_FLOW_NODE = `${BASE_URL}/workflow/node/`;
export const WORK_FLOW_EDGE = `${BASE_URL}/workflow/edge/`;
export const RESIZABLE_NODE = `${BASE_URL}/workflow/background/`;

export const GET_NOTIFICATION_LIST = `${BASE_URL}/workflow/v2/notification-types`;
export const GET_NOTIFICATION_USERS = `${BASE_URL}/workflow/v2/notification-users/`;
export const GET_NOTIFICATION_METHODS = `${BASE_URL}/workflow/v2/notification-methods/`;

export const GET_BRANCH_NODE_DETAILS = `${BASE_URL}/workflow/v2/field-list`;
export const GET_CONDITION_MASTER_LIST = `${BASE_URL}/workflow/v2/get-conditions`;
export const POST_CONDITION_MASTER = `${BASE_URL}/workflow/v2/condition-master/`;
export const GET_BRANCH_NODE_CONDITION_LIST = `${BASE_URL}/workflow/v2/condition-list`;
export const GET_BRANCH_NODE_ELEMENTS_BY_CONDITION = `${BASE_URL}/workflow/v2/condition-field-list`;

export const WORK_FLOW_PROCESS = `${BASE_URL}/workflow/process/`;
export const PROCESS_DETAILS = `${BASE_URL}/workflow/v2/process/`;

export const WORK_FLOW_CATEGORIES = `${BASE_URL}/workflow/category/`;
export const WORK_FLOW_REQUEST = `${BASE_URL}/workflow/request/`;
export const WORK_FLOW_FORM = `${BASE_URL}/workflow/form/`;
export const WORK_CREATE_TRACK = `${BASE_URL}/api/fe/create-track/`;
export const LOGIN = `${BASE_URL}/api/login/`;
export const ORGANIZATION_STAFF = `${BASE_URL}/organization/staff`;
export const GETINBOX = `${BASE_URL}/api/fe/inbox`;
// export const GETINBOXDETAILS = `${BASE_URL}/workflow/inbox-detail/`;
export const GETINBOXDETAILS = `${BASE_URL}/api/fe/inbox/`;
export const GETINBOXTRACKHISTORY = `${BASE_URL}/api/fe/get-track-history/`;

export const PROCESS_TRACK = `${BASE_URL}/workflow/track/`;
export const PROCESS_NEXT_TRACK = `${BASE_URL}/workflow/create-next-track/`;

export const MODULES = `${BASE_URL}/workflow/modules/`;

export const GRAPH_BUILDER_SHEET = `${BASE_URL}/graph-builder/sheet/`;
export const GRAPH_BUILDER_CHART_DETAILS = `${BASE_URL}/graph-builder/chart-details/`;
export const GRAPH_BUILDER_DATA_SET = `${BASE_URL}/graph-builder/data-set/`;
export const GRAPH_BUILDER_LIST_BY_DATASET = `${BASE_URL}/graph-builder/data-set/`;

export const GET_PROCESS_LANGUAGE = `${BASE_URL}/api/administration/language/`;
export const CREATE_PROCESS = `${BASE_URL}/workflow/process/`;

export const GET_FILTER_LIST = `${BASE_URL}/api/fe/get-filter/`;
export const ADD_FILTER = `${BASE_URL}/api/fe/add-filter/`;
export const GET_USER_FILTER = `${BASE_URL}/api/fe/get-user-filter/`;
export const DELETE_FILTER = `${BASE_URL}/api/fe/delete-filter/`;
export const UPDATE_FILTER = `${BASE_URL}/api/fe/update-filter/`;
export const GET_ADMINISTRATION_PROCESS = `${BASE_URL}/administration/process`;
export const GET_ADMINISTRATION_PROCESS_DETAILS = `${BASE_URL}/administration/process`;

export const GET_ADMINISTRATION_REQUEST = `${BASE_URL}/administration/process_request`;
export const GET_ADMINISTRATION_REQUEST_DETAILS = `${BASE_URL}/workflow/v2/request-status`;

export const VALIDATE_PROCESS = `${BASE_URL}/workflow/v2/validate-process`;
export const PUBLISH_PROCESS = `${BASE_URL}/api/fe/publish-process/`;
export const GET_PUBLISH_CHANGES_PROCESS = `${BASE_URL}/workflow/v2/unpublish-status/`;

export const DEPARTMENT_URL = `${BASE_URL}/api/organization/department`;
export const GET_DEPARTMENT_URL = `${BASE_URL}/api/fe/departments`;

export const CATEGORY_URL = `${BASE_URL}/api/administration/category/`;
export const GET_CATEGORY_URL_BY_DEPARTMENT = `${BASE_URL}/api/fe/category/`;
export const GROUP_URL = `${BASE_URL}/group/`;
export const CREATE_CATEGORY = `${BASE_URL}/workflow/category/`;

export const FAVORITE_URL = `${BASE_URL}/workflow/favorite-process/`;
export const TEMPLATE = `${BASE_URL}/workflow/v2/templates`;
export const TEMPLATE_DETAILS = `${BASE_URL}/workflow/v2/templates-detail`;

export const GET_ATTACHMENT_BY_REQUEST = `${BASE_URL}/api/fe/get-attachments`;
export const INBOX_IS_READ = `${BASE_URL}/workflow/v2/is-read/`;
export const GET_STAFF_ORGANIZATION = `${BASE_URL}/api/organization/staff`;


export const LIST_NODE_USERS = `${BASE_URL}/api/fe/node-user`;

export const GET_TABLE_LIST = `${BASE_URL}/api/fe/dropdown-metadata/`;
export const GET_COLUMN_LIST_BY_TABLEID = `${BASE_URL}/api/fe/tables/`;

export const GET_FORM_REQUIRED_DOCUMENTS = `${BASE_URL}/api/fe/form-requirements/`
