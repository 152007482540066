import { MenuItem, Select } from "@mui/material";
import React from "react";
import { FormattedMessage } from "react-intl";

function LangDropDOwn({handleOnClick, value}: {handleOnClick: (e: any) => any , value: any}){

    return <>
        <Select size="small" onChange={handleOnClick} value={value}>
            <MenuItem value="en"><FormattedMessage id="English"></FormattedMessage></MenuItem>
            <MenuItem value="ar"><FormattedMessage id="Arabic"></FormattedMessage> </MenuItem>
        </Select>
    </>
}

export default LangDropDOwn;