import {
  CATEGORY_URL,
  DEPARTMENT_URL,
  GET_ADMINISTRATION_PROCESS,
  GET_ADMINISTRATION_PROCESS_DETAILS,
  GET_ADMINISTRATION_REQUEST,
  GET_ADMINISTRATION_REQUEST_DETAILS,
  GET_CATEGORY_URL_BY_DEPARTMENT,
  GET_DEPARTMENT_URL,
  GET_STAFF_ORGANIZATION,
  GROUP_URL,
} from "./urls";

const token = localStorage.getItem("token");

export async function fetchAdminProcessList() {
  try {
    const response = await fetch(GET_ADMINISTRATION_PROCESS, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching inbox details:", error);
    throw error;
  }
}

export async function fetchAdminRequestList() {
  try {
    const response = await fetch(GET_ADMINISTRATION_REQUEST, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching inbox details:", error);
    throw error;
  }
}

export async function fetchAdminProcessDetails(id: any) {
  try {
    const response = await fetch(`${GET_ADMINISTRATION_PROCESS_DETAILS}${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    });

    if (!response.ok) {
      // If the response is not ok, throw an error with the status text
      throw new Error(`HTTP error! status: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    // Log the error and rethrow it so it can be handled by the caller
    console.error("Error fetching inbox details:", error);
    throw error;
  }
}

export async function fetchAdminRequestDetails(id: any) {
  try {
    const response = await fetch(
      `${GET_ADMINISTRATION_REQUEST_DETAILS}/${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      }
    );

    if (!response.ok) {
      // If the response is not ok, throw an error with the status text
      throw new Error(`HTTP error! status: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    // Log the error and rethrow it so it can be handled by the caller
    console.error("Error fetching inbox details:", error);
    throw error;
  }
}

export async function fetchCategories(
  page: number = 1,
  pageSize: number = 100
) {
  const url = new URL(CATEGORY_URL);

  const params = new URLSearchParams({
    page: page.toString(),
    page_size: pageSize.toString(),
  });

  url.search = params.toString();
  try {
    const response = await fetch(url?.toString(), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    });

    if (!response.ok) {
      // If the response is not ok, throw an error with the status text
      throw new Error(`HTTP error! status: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    // Log the error and rethrow it so it can be handled by the caller
    console.error("Error fetching inbox details:", error);
    throw error;
  }
}

export async function fetchCategoriesHome(department: string, search?: string) {
  const url = new URL(GET_CATEGORY_URL_BY_DEPARTMENT + department);
  const params = new URLSearchParams();

  if (search) {
    params.append("search", search);
  }

  url.search = params.toString();
  try {
    const response = await fetch(url?.toString(), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    });

    if (!response.ok) {
      // If the response is not ok, throw an error with the status text
      throw new Error(`HTTP error! status: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    // Log the error and rethrow it so it can be handled by the caller
    console.error("Error fetching inbox details:", error);
    throw error;
  }
}

export async function fetchDeparments(page: number, pageSize: number) {
  const url = new URL(DEPARTMENT_URL);

  const params = new URLSearchParams({
    page: page.toString(),
    page_size: pageSize.toString(),
  });

  url.search = params.toString();

  try {
    const response = await fetch(url?.toString(), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    });

    if (!response.ok) {
      // If the response is not ok, throw an error with the status text
      throw new Error(`HTTP error! status: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    // Log the error and rethrow it so it can be handled by the caller
    console.error("Error fetching inbox details:", error);
    throw error;
  }
}

export async function fetchDeparmentsHome(search?: string) {
  const url = new URL(GET_DEPARTMENT_URL);

  const params = new URLSearchParams();

  if (search) {
    params.append("search", search);
  }

  url.search = params.toString();

  try {
    const response = await fetch(url?.toString(), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    });

    if (!response.ok) {
      // If the response is not ok, throw an error with the status text
      throw new Error(`HTTP error! status: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    // Log the error and rethrow it so it can be handled by the caller
    console.error("Error fetching inbox details:", error);
    throw error;
  }
}

export async function fetchUserGroups() {
  try {
    const response = await fetch(GROUP_URL, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    });

    if (!response.ok) {
      // If the response is not ok, throw an error with the status text
      throw new Error(`HTTP error! status: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    // Log the error and rethrow it so it can be handled by the caller
    console.error("Error fetching inbox details:", error);
    throw error;
  }
}

export async function fetchUserGroupByID(id: any) {
  try {
    const response = await fetch(GROUP_URL + "/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    });
    const data = await response.json();

    if (!response.ok) {
      // If the response is not ok, throw an error with the status text
      throw new Error(`HTTP error! status: ${data}`);
    }

    return data;
  } catch (error) {
    // Log the error and rethrow it so it can be handled by the caller
    console.error("Error fetching inbox details:", error);
    throw error;
  }
}

export async function fetchStaffs(page: number, pageSize: number) {
  const url = new URL(GET_STAFF_ORGANIZATION);

  const params = new URLSearchParams({
    page: page.toString(),
    page_size: pageSize.toString(),
  });

  url.search = params.toString();

  try {
    const response = await fetch(url?.toString(), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    });

    if (!response.ok) {
      // If the response is not ok, throw an error with the status text
      throw new Error(`HTTP error! status: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    // Log the error and rethrow it so it can be handled by the caller
    console.error("Error fetching inbox details:", error);
    throw error;
  }
}
