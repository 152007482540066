import { useState } from "react";
import { Collapse } from "@mui/material";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { FormattedMessage } from "react-intl";
import { BsChevronDown } from "react-icons/bs";

export default function SideMenuItem({
  label,
  icon,
  toggleMenu,
  path,
  submenu,
}: {
  label: string;
  icon: JSX.Element;
  toggleMenu: boolean;
  path: string;
  submenu?: { label: string; route: string; icon?: JSX.Element }[];
}) {
  console.log(label);
  const [isSubmenuOpen, setSubmenuOpen] = useState(false);
  const location = useLocation(); // Get current path for active styling
  const navigate = useNavigate(); // To programmatically navigate

  const handleSubmenuClick = () => {
    setSubmenuOpen((state) => !state);
    if (!submenu) {
      navigate(path);
    }
  };

  return (
    <>
      {/* Main menu item */}
      <li
        className={`group mx-3 mt-1 mb-2 items-center hover:bg-[#0060abb3] rounded-md px-4 cursor-pointer ${
          path === location.pathname ||
          submenu?.some((sub) => sub.route === location.pathname)
            ? "bg-[#0060AB] text-white"
            : "hover:bg-gray-100 text-[#4d4d4d]"
        }`}
        onClick={handleSubmenuClick}
      >
        <div className={`flex flex-row items-center py-2 ${toggleMenu ? "justify-center" : "justify-between"}`}>
          <div className="flex flex-row items-center gap-2">
            {" "}
            <div
              className={
                path === location.pathname ||
                submenu?.some((sub) => sub.route === location.pathname)
                  ? "text-white"
                  : "text-[#757575]"
              }
            >
              {icon}
            </div>
            <Typography
              variant="h5"
              color="inherit"
              className={`${
                !toggleMenu
                  ? "opacity-100 block"
                  : "opacity-0 text-[0px] hidden"
              }`}
            >
              <FormattedMessage id={label} />
            </Typography>
          </div>
          {submenu && !toggleMenu && <BsChevronDown />}
        </div>
      </li>

      {/* Submenu items */}
      {!toggleMenu && submenu && (
        <Collapse in={isSubmenuOpen} timeout="auto" unmountOnExit>
          <ul className="ml-[40px] mr-[20px] list-none">
            {submenu.map((subItem, index) => (
              <li
                key={index}
                className={`flex items-center gap-2 px-3 py-1 rounded-md cursor-pointer transition-all duration-200 mb-1 ${
                  location.pathname === subItem.route ? "bg-blue-100 text-[#0060AB]" : "text-gray-600 hover:bg-gray-100"
                }`}
                onClick={() => {
                  navigate(subItem.route, { state: { _display: false } });
                }}
              >
                {subItem.icon && <span>{subItem.icon}</span>}
                <Typography variant="subtitle1">
                  <FormattedMessage id={subItem.label} />
                </Typography>
              </li>
            ))}
          </ul>
        </Collapse>
      )}
    </>
  );
}
