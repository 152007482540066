/* eslint-disable react-hooks/exhaustive-deps */
// @ts-nocheck
import { Save } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { debounce } from "lodash";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import toast from "react-hot-toast";
import { FiAlertCircle } from "react-icons/fi";
import { LuDiamond } from "react-icons/lu";
import {
  MdOutlineNotificationAdd,
  MdOutlineShare,
  MdOutlineSpaceDashboard,
  MdPublish,
} from "react-icons/md";
import { FormattedMessage, useIntl } from "react-intl";
import { ResizableBox } from "react-resizable";
import "react-resizable/css/styles.css";
import { useParams } from "react-router";
import {
  Background,
  ConnectionLineType,
  ConnectionMode,
  MiniMap,
  Node,
  ReactFlow,
  useEdgesState,
  useNodesState,
  useReactFlow,
} from "reactflow";
import {
  createResizable,
  createWorkFlowEdge,
  createWorkFlowNode,
  fetchPublishChanges,
  listWorkFlowNodes,
  PostPublishProcess,
  updateWorkFlowNode,
} from "../../apis/flowBuilder";
import { fetchProcessDetails } from "../../apis/process";
import DialogCustomized from "../../components/Dialog/DialogCustomized";
import FlowItemsMenu from "../../components/FlowItemsMenu/FlowItemsMenu";
import FormBuilderNew from "../../components/FormBuilderNew";
import InputField from "../../components/FormElements/components/InputField";
import IconButton from "../../components/ui/IconButton";
import WorkFlowNode from "../../components/WorkFlowNode/WorkFlowNode";
import NodeUserList from "../../containers/NodeUserList/NodeUserList";
import useTranslation from "../../hooks/useTranslation";
import { CustomNodeTypes, customNodeTypes } from "../../utils/customFlowItems";
import BranchNode from "./BranchNode";
import CustomEdge from "./CustomEdge";
import NodeWrapper from "./NodeWrapper";
import ResizableNode from "./ResizableNode";
import "./workflow.css";
import AIChatBox from "./AIChatBox";
let id = 1;
const getId = () => `flowai-${id++}`;

export default function WorkFlow() {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  const [formContainerWidth, setFormContainerWidth] = useState(800);
  const [processDetails, setProcessDetails] = useState({});
  const [isDrag, setIsDrag] = useState(true);

  const [undo, setUndo] = useState([]);
  const [redo, setRedo] = useState([]);

  const [publishDialog, setPublishDialog] = useState(false);
  const [publishForm, setpublishForm] = useState({
    version: "",
    comments: "",
  });
  const [versionError, setversionError] = useState(false);

  const [loader, setLoader] = useState(true);

  const { locale } = useIntl();
  const { translate } = useTranslation();

  const [selectedNode, setSelectedNode] = useState<Node | null>();

  function pushtoUndo(undo) {
    setRedo([]);
    setUndo((state) => [...state, undo].slice(-10));
  }

  const nodeTypes = useMemo(
    () => ({
      WorkFlowNode: (props: any) => {
        return (
          <NodeWrapper>
            <WorkFlowNode
              onUserBtnClick={onUserBtnClick}
              onFormBtnClick={onFormBtnClick}
              selectedNodeId={selectedNodeId}
              setIsDrag={setIsDrag}
              {...props}
            />
          </NodeWrapper>
        );
      },
      ResizableNode: (props: any) => {
        return (
          <NodeWrapper>
            <ResizableNode {...props} />
          </NodeWrapper>
        );
      },
      BranchNode: (props) => {
        return (
          <NodeWrapper>
            <BranchNode {...props} />
          </NodeWrapper>
        );
      },
    }),
    [selectedNode]
  );

  const EdgeType: any = useMemo(
    () => ({
      AF: (props: any) => (
        <CustomEdge {...props} type="AF" setIsDrag={setIsDrag} />
      ),
    }),
    []
  );

  const [selectedMenuItem, setSelectedMenuItem] = useState<
    CustomNodeTypes | undefined
  >(CustomNodeTypes.WORKFLOWNODE);
  const reactFlowWrapper = useRef<HTMLDivElement | null>(null);
  const [selectedNodeId, setSelectedNodeId] = useState<string | null>();
  const reactFlowInstance = useReactFlow();
  const { processId } = useParams();

  const [contextMenu, setContextMenu] = useState<{
    visible: boolean;
    x: number;
    y: number;
  }>({ visible: false, x: 0, y: 0 });

  console.log(reactFlowInstance);

  const onUserBtnClick = (id: any) => {
    setSelectedNodeId((prevSelectedId) => (prevSelectedId === id ? null : id));
    setSelectedNode(null); // Ensure the form view is reset when toggling the user list.
  };

  const onFormBtnClick = (node: any) => {
    setSelectedNode((prevSelectedNode) =>
      prevSelectedNode?.id === node.id ? null : node
    );
    setSelectedNodeId(null);
  };

  const [previousNode, setPreviousNode] = useState<Node | null>(null);

  async function listworkflowNode() {
    try {
      const data: any = await listWorkFlowNodes(processId);
      setLoader(false);
      setNodes(data?.nodes);
      setEdges(data?.edges);
    } catch (err) {
      setLoader(false);
    }
  }

  useEffect(() => {
    if (selectedNode && previousNode) {
      if (selectedNode.id === previousNode.id) {
      } else {
        // code to remove the form view
        setSelectedNode(null);
        setSelectedNodeId(null);
      }
    }
    setPreviousNode(selectedNode ?? null);
  }, [selectedNode]);

  useEffect(() => {
    listworkflowNode();
  }, [processId]);

  // useEffect(() => {
  //   pollUnpublishedChanges();
  // }, [nodes, edges]);

  const prevNodesRef = useRef(nodes);
  const prevEdgesRef = useRef(edges);

  // useEffect(() => {
  //   if (
  //     JSON.stringify(prevNodesRef.current) === JSON.stringify(nodes) &&
  //     JSON.stringify(prevEdgesRef.current) === JSON.stringify(edges)
  //   ) {
  //     return; // No real change, exit early
  //   }

  //   prevNodesRef.current = nodes;
  //   prevEdgesRef.current = edges;

  //   const debouncedPoll = debounce(() => {
  //     pollUnpublishedChanges();
  //   }, 300);

  //   debouncedPoll();

  //   return () => {
  //     debouncedPoll.cancel();
  //   };
  // }, [nodes, edges]);

  useEffect(() => {
    fetchProcessById();
  }, [processId]);

  useEffect(() => {
    const pane = reactFlowWrapper.current;
    const doubleClickDelay = 300;
    let lastClickTime = 0;

    const onPaneClick = async (event: React.MouseEvent) => {
      // Prevent default pane double-click if a node is clicked
      if ((event.target as HTMLElement).closest(".react-flow__node")) {
        return;
      }

      const currentTime = new Date().getTime();
      if (currentTime - lastClickTime < doubleClickDelay) {
        if (!reactFlowWrapper.current) return;
        if (selectedMenuItem) {
          const reactFlowBounds =
            reactFlowWrapper.current.getBoundingClientRect();
          const newNode: Node = {
            ...customNodeTypes[selectedMenuItem],
            id: getId(),
            position: reactFlowInstance.project({
              x: event.clientX - reactFlowBounds.left,
              y: event.clientY - reactFlowBounds.top,
            }),
          };
          setNodes((prev) => prev.concat(newNode));
        }
      }

      lastClickTime = currentTime;
    };

    if (pane) {
      pane.addEventListener("click", onPaneClick as unknown as EventListener);
    }

    return () => {
      if (pane) {
        pane.removeEventListener(
          "click",
          onPaneClick as unknown as EventListener
        );
      }
    };
  }, [reactFlowInstance, selectedMenuItem]);

  useEffect(() => {
    (async () => {
      const lastAddedNode = nodes[nodes.length - 1];

      if (lastAddedNode?.id?.startsWith("flow")) {
        const payload = { ...lastAddedNode, process: processId };

        if (
          lastAddedNode.type === CustomNodeTypes.WORKFLOWNODE ||
          lastAddedNode.type === CustomNodeTypes.BRANCHNODE
        ) {
          const data = await createWorkFlowNode(payload);
          const node = [...nodes];
          node[node.length - 1] = data;
          pushtoUndo({ nodes: nodes.slice(0, -1), edges });
          setNodes(node);
        } else {
          await createResizable(payload);
        }
      }
    })();
  }, [nodes.length]);

  const onConnect = useCallback(
    async (params: any) => {
      try {
        reactFlowInstance.getNode(params.target);

        // Check if an edge with the same source and target already exists
        const edgeExists = edges.some(
          (edge) =>
            edge.source === params.source && edge.target === params.target
        );

        if (!edgeExists) {
          const data = await createWorkFlowEdge({
            ...params,
            translations: {
              ar: { name: "إضافة اسم الإجراء" },
              en: { name: "Add Action Name" },
            },
          });

          setEdges((prevEdges) => [
            ...prevEdges,
            { ...data, data: { translations: data.translations } },
          ]);
        }
      } catch (err) {}
      // listworkflowNode();
    },
    [edges]
  );
  const handleSelectMenuItem = (item: CustomNodeTypes | undefined) => {
    setSelectedMenuItem(item);
  };

  function handleResize(e, data) {
    setFormContainerWidth(data.size.width);
  }

  const handleContextMenu = (event: React.MouseEvent) => {
    event.preventDefault(); // Prevent default right-click menu
    if (reactFlowWrapper.current) {
      const bounds = reactFlowWrapper.current.getBoundingClientRect();
      setContextMenu({
        visible: true,
        x: event.clientX - bounds.left,
        y: event.clientY - bounds.top,
      });
    }
  };

  const handleAddNode = (nodeType: CustomNodeTypes) => {
    const newNode: Node = {
      ...customNodeTypes[nodeType],
      id: getId(),
      position: reactFlowInstance.project({
        x: contextMenu.x,
        y: contextMenu.y,
      }),
    };
    setNodes((prev) => prev.concat(newNode));
    setContextMenu({ visible: false, x: 0, y: 0 }); // Hide context menu
  };

  // function onConnectEnd(event, item) {
  //   event.preventDefault(); // Prevent default right-click menu
  //   if (reactFlowWrapper.current) {
  //     const bounds = reactFlowWrapper.current.getBoundingClientRect();
  //     setTimeout(() => {
  //       setContextMenu({
  //         visible: true,
  //         x: event.clientX - bounds.left,
  //         y: event.clientY - bounds.top,
  //       });
  //     }, 0);
  // }
  // }

  const NodeIconMap = {
    [CustomNodeTypes.WORKFLOWNODE]: (
      <MdOutlineShare color="blue" style={{ fontSize: "10px" }} />
    ),
    [CustomNodeTypes.BRANCHNODE]: (
      <LuDiamond color="blue" style={{ fontSize: "10px" }} />
    ),
    [CustomNodeTypes.RESIZABLENODE]: (
      <MdOutlineSpaceDashboard color="blue" style={{ fontSize: "10px" }} />
    ),
    [CustomNodeTypes.NOTIFICATIONNODE]: (
      <MdOutlineNotificationAdd color="blue" style={{ fontSize: "10px" }} />
    ),
  };

  const [isVisible, setisVisible] = useState(false);

  const publishProcess = async () => {
    const payload = {
      uuid: processId,
      version_name: publishForm.version,
      comments: publishForm.comments,
    };
    try {
      await PostPublishProcess(payload);
      setisVisible(false);
      toast.success("Successfully deployed the process");
    } catch (error) {
      toast.error(error.message);
    } finally {
      setPublishDialog(false);
    }
  };

  const publishFormChange = (name: string, value: string) => {
    setpublishForm((prev) => ({ ...prev, [name]: value }));
  };

  const pollUnpublishedChanges = async () => {
    const payload = {
      uuid: processId,
    };
    try {
      const res = await fetchPublishChanges(payload);
      setisVisible(res.show);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchProcessById = async () => {
    try {
      const res = await fetchProcessDetails(processId);
      console.log(res);
      setProcessDetails(res);
    } catch (error) {}
  };

  function getTranslatedText(data: any, key: string) {
    return data?.[locale]?.[key] || data?.[key];
  }

  // useEffect(() => {
  //   setUndo((state) => [...state, { nodes, edges }]);
  // }, [nodes.length, edges.length]);

  if (loader) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "calc(100vh - 150px)",
        }}
      >
        <CircularProgress sx={{ height: "10px", width: "10px" }} />{" "}
      </div>
    );
  }

  return (
    <>
      <div className="border-b border-gray-300 h-[49px] p-2 pl-4 flex items-center justify-between">
        <div className="flex-1 min-w-0 ltr:text-left rtl:text-right">
          <div className="text-sm font-medium text-gray-900 ltr:pr-2 rtl:pl-2">
            {getTranslatedText(processDetails.translations, "name")} -
            <span className="text-blue-600 font-semibold ltr:ml-1 rtl:mr-1">
              {getTranslatedText(processDetails.category, "name")}
            </span>
          </div>
        </div>

        <Stack direction="row" alignItems={"center"}>
          <Tooltip title={translate("saveAsTemplate")}>
            <IconButton size="small">
              <Save color="primary" />
            </IconButton>
          </Tooltip>

          <div
            className={`shadow appearance-none border rounded-md w-full overflow-hidden flex items-center h-[30px]`}
          >
            <select
              onChange={() => {}}
              className="w-full p-2 text-sm text-base text-gray-700 bg-white focus:outline-none"
              value=""
            >
              <option value="">
                <FormattedMessage id="selectTemplate"></FormattedMessage>
              </option>
              {[].map((option, index) => (
                <option key={index} value={option.name}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>
          {/* <Dropdown
            options={[]}
            labelKey="name"
            valueKey="uuid"
            onChange={() => {}}
            name={"template"}
          /> */}
        </Stack>
        <div className="flex items-center gap-2 ml-2">
          <div className="w-auto flex items-center justify-center gap-2">
            <span className="text-sm font-medium text-gray-900">Version:</span>

            <div
              className={`shadow appearance-none border rounded-md w-full overflow-hidden flex items-center h-[30px]`}
            >
              <select
                onChange={() => {}}
                className="w-full p-2 text-sm text-base text-gray-700 bg-white focus:outline-none"
                value=""
              >
                <option value="">
                  <FormattedMessage id="selectVersion"></FormattedMessage>
                </option>
                {[{ name: "1.0", id: "test" }].map((option, index) => (
                  <option key={index} value={option.name}>
                    {option.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="p-[10px] flex items-center gap-3">
            <div className="flex-shrink-0">
              <FiAlertCircle
                className={`h-5 w-5 ${
                  isVisible ? "text-blue-500" : "text-gray-400"
                }`}
              />
            </div>

            <div className="py-[3px] px-[10px] min-w-0 ltr:text-left rtl:text-right bg-gray-100 flex items-center justify-center rounded-md ltr:pr-2 rtl:pl-2 w-full">
              <div className="text-sm font-medium text-gray-900">
                {isVisible ? (
                  <FormattedMessage id="unPublishedChanges" />
                ) : (
                  <FormattedMessage id="lastVersionUpdated" />
                )}
              </div>
            </div>

            <button
              // disabled={!isVisible}
              onClick={() => setPublishDialog(true)}
              className={`flex-shrink-0 inline-flex items-center gap-1.5 px-3 py-1.5 text-md font-medium rounded-md transition-colors ${
                false
                  ? "bg-gray-400 text-gray-600 cursor-not-allowed"
                  : "bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              }`}
            >
              <MdPublish />
              <FormattedMessage id="publish" />
            </button>
          </div>
        </div>
      </div>
      <div
        className="relative flex"
        style={{
          height: "calc(100vh - 113px)",
        }}
      >
        <div
          className={`flex-grow w-[200px]`}
          ref={reactFlowWrapper}
          onContextMenu={handleContextMenu} // Add right-click listener
          dir="ltr"
        >
          <ReactFlow
            nodes={nodes}
            edges={edges}
            onClick={(e) => {
              setContextMenu({ visible: false, x: 0, y: 0 });
            }}
            onNodesChange={(changes) => {
              onNodesChange(changes);
            }}
            onEdgesChange={(changes) => {
              onEdgesChange(changes);
            }}
            fitView={true}
            nodeTypes={nodeTypes}
            onConnect={async (params) => {
              await onConnect(params);
              pushtoUndo({ nodes, edges });
            }}
            onNodesDelete={(param) => {
              pushtoUndo({ nodes, edges });
            }}
            onEdgesDelete={(param) => {
              pushtoUndo({ nodes, edges });
            }}
            snapToGrid
            connectionLineType={ConnectionLineType.Bezier}
            connectionMode={ConnectionMode.Loose}
            edgeTypes={EdgeType}
            onNodeDragStart={(event, node) => {
              pushtoUndo({ nodes, edges });
            }}
            onNodeDragStop={async (event, node) => {
              await updateWorkFlowNode(node);
            }}
            nodesDraggable={isDrag}
            panOnDrag={isDrag}
            onEdgeClick={(event, edge) => {}}
            onNodeClick={(event, node) => {
              if (node.id !== selectedNode?.id) {
              } else {
              }
            }}
          >
            <MiniMap />
            {/* <Controls /> */}
            <Background gap={12} size={1} />
          </ReactFlow>
          <FlowItemsMenu
            onSelectItem={handleSelectMenuItem}
            isUndoDisabled={!undo.length}
            isRedoDisabled={!redo.length}
            onUndo={(e) => {
              setIsDrag(true);
              if (undo.length) {
                const lastState = undo[undo.length - 1];
                setNodes(lastState.nodes);
                setEdges(lastState.edges);
                setUndo((state) => state.slice(0, -1));
                setRedo((state) => [...state, { nodes, edges }]);
              }
            }}
            onRedo={(e) => {
              setIsDrag(true);
              if (redo.length) {
                const lastState = redo[redo.length - 1];
                setNodes(lastState.nodes);
                setEdges(lastState.edges);
                setRedo((state) => state.slice(0, -1));
                setUndo((state) => [...state, { nodes, edges }]);
              }
            }}
          />
          <AIChatBox selectedNodeId={selectedNode?.id} />
        </div>
        {selectedNode?.type === "WorkFlowNode" && (
          <ResizableBox
            className="z-[1000]"
            width={800}
            height={0}
            axis="x"
            resizeHandles={["w"]}
            onResizeStop={handleResize}
            minConstraints={[500, 0]}
            maxConstraints={[1200, 0]}
            handle={
              <div
                style={{
                  width: "2px",
                  cursor: "ew-resize",
                  // backgroundColor: "#007bff",
                  height: "100vh",
                  position: "absolute",
                  top: 0,
                  left: 0,
                }}
              />
            }
          >
            {selectedNode.id && (
              <FormBuilderNew
                selectedNode={selectedNode}
                onClose={() => {
                  setSelectedNode(null);
                  setFormContainerWidth(800);
                }}
                formContainerWidth={formContainerWidth}
              />
            )}
          </ResizableBox>
        )}

        {selectedNodeId && (
          <NodeUserList
            onClose={() => setSelectedNodeId("")} // This will close the sidebar
            selectedNodeId={selectedNodeId}
            show={!!selectedNodeId} // Control visibility based on selectedNodeId
            nodeName={selectedNode?.data?.label || "NAMEEEE"}
            locale={locale}
          />
        )}
        {contextMenu.visible && (
          <div
            className="absolute bg-white shadow-md rounded-md border border-[rgb(211, 211, 211)]"
            style={{ top: contextMenu.y, left: contextMenu.x }}
          >
            <div
              style={{
                borderBottom: "1px solidrgb(211, 211, 211)",
                background: "#f9f9f9",
                borderTopLeftRadius: "6px",
                borderTopRightRadius: "6px",
              }}
            >
              <Typography
                variant="caption"
                className="p-1 pr-1.5 pl-2.5"
                fontSize={"10px"}
                fontWeight={"500"}
                color={"#808080"}
              >
                Add Block
              </Typography>
            </div>
            <div className="p-0.5">
              {Object.entries(CustomNodeTypes)
                .slice(1)
                .map(([key, value]) => (
                  <div
                    key={value}
                    className="p-1 pl-2 hover:bg-gray-200 cursor-pointer rounded"
                    onClick={() => handleAddNode(CustomNodeTypes[key])}
                  >
                    <div className="flex justify-start items-center gap-2">
                      <div>{NodeIconMap?.[value]}</div>
                      <Typography variant="subtitle1" fontSize={"10px"}>
                        {customNodeTypes?.[value]?.data?.label}
                      </Typography>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>

      <DialogCustomized
        open={publishDialog}
        handleClose={() => setPublishDialog(false)}
        actions={
          <Stack direction="row" spacing={2}>
            <Button onClick={() => setPublishDialog(false)}>
              {translate("cancel")}
            </Button>
            <Button
              variant="contained"
              disableElevation
              onClick={() => {
                if (publishForm.version) {
                  publishProcess();
                } else {
                  setversionError(true);
                }
              }}
            >
              {translate("publish")}
            </Button>
          </Stack>
        }
        content={
          <Stack spacing={1}>
            <InputField
              label={translate("versionName")}
              value={publishForm.version}
              onChange={(e) => publishFormChange("version", e)}
              error={versionError && !publishForm.version}
              helperText={
                versionError &&
                !publishForm.version &&
                "Version name is required"
              }
            />

            <Stack>
              <Typography
                variant="subtitle1"
                textTransform={"capitalize"}
                mb={1}
              >
                <FormattedMessage id="comments"></FormattedMessage>
              </Typography>
              <div
                style={{
                  transition: "opacity 0.3s ease",
                  marginBottom: "10px",
                  padding: "10px",
                  borderRadius: "0.25rem",
                  border: "1px solid #e0e0e0",
                  backgroundColor: "#ffffff",
                  position: "relative",
                  minHeight: "130px",
                }}
                className="shadow"
              >
                <textarea
                  style={{
                    height: "100%",
                    width: "100%",
                    outline: "none",
                  }}
                  rows={5}
                  value={publishForm?.comments}
                  onChange={(e) =>
                    publishFormChange("comments", e.target.value)
                  }
                ></textarea>
              </div>
            </Stack>
          </Stack>
        }
        title={translate("publishWorkflow")}
      />
    </>
  );
}
