import React, { useState, useEffect, useRef, useMemo } from "react";
import "./DynamicForm.css";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Switch,
  Typography,
} from "@mui/material";
import { elements_type } from "../FormElements/constants";
import SignatureCanvas from "react-signature-canvas";
import InputField from "../FormElements/components/InputField";
import Dropdown from "../Dropdown/Dropdown";
import CheckBox from "../FormElements/components/CheckBox";
import DatePickerCustom from "../FormElements/components/DatePicker";
import FileUpload from "../FormElements/components/FileUpload";
import MultiFileUpload from "../FormElements/components/MultiFileUpload";
import MultiSelectField from "../FormElements/components/MultiSelectDropdown";
import InfoIcon from "@mui/icons-material/Info";
import DateTimePickerCustom from "../FormElements/components/DateTimePicker";
import { FormattedMessage, useIntl } from "react-intl";
import toast from "react-hot-toast";
import LangTab from "../FormElements/newcompnents/LangTab";
import useTranslation from "../../hooks/useTranslation";
import { reach } from "yup";
import {
  fetchColumnValues,
  fetchColumnValuesByParent,
  fetchDependantTables,
} from "../../apis/flowBuilder";
import OpenLayersMap from "../../containers/map/OpenLayersMap";

const DynamicFormPopup = ({
  formData = [],
  actionList,
  onClose,
  onSubmit,
  loader,
  isPreview,
  previewActiveLang,
}: any) => {
  const [formState, setFormState] = useState<any>({});
  const [selectedAction, setSelectedAction] = useState("");
  const [isSubmitted, setSubmitted] = useState(false);
  const [formError, setformError] = useState({});
  const [formErrorMessage, setformMessage] = useState({});
  const [cascadingOptions, setCascadingOptions] = useState({});
  console.log(formData);
  console.log(formState);

  const { locale } = useIntl();
  const { translate } = useTranslation();

  const canvasRefs = useRef<any>({});
  const addToRefs = (el: any, id: string) => {
    if (el) {
      canvasRefs.current[id] = el;
    }
  };

  function fileToBase64(file: any) {
    return new Promise((resolve, reject) => {
      const reader: any = new FileReader();
      reader.onload = () => resolve(reader.result.split(",")[1]); // Extract Base64 part
      reader.onerror = (error: any) => reject(error);
      reader.readAsDataURL(file); // Read the file as a data URL
    });
  }
  useEffect(() => {
    const initialFormState: any = {};
    formData?.forEach((field: any) => {
      if (field.element_type === "DATE") {
        initialFormState[field.id] = {
          label: field?.label,
          id: field.id,
          value: new Date().toISOString().split("T")[0],
        };
      }
      if (field.element_type === elements_type.DATE_TIME) {
        initialFormState[field.id] = {
          label: field?.label,
          id: field.id,
          value: new Date().toISOString().slice(0, 16),
        }; // Set to today's date
      }
    });
    setFormState((prev: any) => ({ ...prev, ...initialFormState }));
  }, [formData]);

  const handleActionChange = (e: any) => {
    setSelectedAction(e.target.value);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const signatureData: any = {};
    setSubmitted(true);

    for (const [id, canvas] of Object.entries(canvasRefs.current) as any) {
      signatureData[id] = {
        id: id,
        value: canvas.toDataURL("image/png"),
        label: JSON?.stringify(
          formData.find((item: any) => item.id === id)?.translate
        ),
      };
    }

    if (isFormValid && selectedAction) {
      onSubmit({ ...formState, ...signatureData }, selectedAction);
    }
  };

  const handleChange = async (field: any, value: any, e: any) => {
    if (field?.element_type === "FILE_UPLOAD") {
      debugger;
      const maxFileSizeBytes = field?.max_file_size * 1024 * 1024; // Convert MB to Bytes”

      if (value?.size > maxFileSizeBytes) {
        toast.error(`File must not exceed ${field?.max_file_size} MB limit`);
        e.target.value = "";
        return;
      }

      value = { url: await fileToBase64(value), name: value.name };
    }

    if (field?.element_type === elements_type?.MULTIFILEUPLOAD) {
      const maxFileSizeBytes = field?.max_file_size * 1024 * 1024;
      const fileList = Array.from(value); // Convert FileList to an array

      const invalidFiles = fileList?.filter(
        (file: any) => file?.size > maxFileSizeBytes
      );

      if (invalidFiles.length > 0) {
        toast.error(`Some files exceed the ${field?.max_file_size} MB limit.`);
        e.target.value = ""; // Clear input field
        return;
      }

      // Use Promise.all to handle all files concurrently
      const base64List = await Promise.all(
        fileList?.map(async (item: any) => {
          return { url: await fileToBase64(item), name: item?.name }; // Convert each file to Base64
        })
      );

      value = base64List;
    }

    if (
      field?.element_type === elements_type.TEXTFIELD ||
      field?.element_type === elements_type.DROPDOWN ||
      field?.element_type === elements_type.MULTISELECTDROPDOWN ||
      field?.element_type === elements_type.CASCADINGDROPDOWN
    ) {
      value = { ...formState?.[field.id]?.value, [locale]: value };
    }
    setFormState((prev: any) => ({
      ...prev,
      [field.id]: {
        value: value,
        id: field.id,
        label: field?.translate,
      },
    }));
  };

  const isFormValidFunction = () => {
    let formFields = formData?.reduce((acc: any, field: any) => {
      if (field.element_type === "GROUPFIELDS") {
        // Add fields from GROUPFIELDS to accumulator
        return [...acc, ...field.fields];
      }
      // Add non-GROUPFIELDS elements directly
      return [...acc, field];
    }, []);

    let isFormFieldValid: any = {};
    formFields
      ?.filter(
        (x: any) =>
          ![
            elements_type.TITLE,
            elements_type.TOGGLE,
            elements_type.DIGITASIGNATURE,
          ].includes(x.element_type)
      )
      .forEach((item: any) => {
        let fieldValue = formState?.[item.id]?.value?.[locale];
        if (
          item.element_type === elements_type.MULTIFILEUPLOAD ||
          item.element_type === elements_type.FILEUPLOAD
        ) {
          fieldValue = formState?.[item.id]?.value;
        }
        if (
          item.element_type === elements_type.TEXTFIELD ||
          item.element_type === elements_type.DROPDOWN ||
          item.element_type === elements_type.MULTISELECTDROPDOWN ||
          item.element_type === elements_type.CASCADINGDROPDOWN
        ) {
          // let arFieldValue = formState?.[item.id]?.value?.["ar"];
          // let enFieldValue = formState?.[item.id]?.value?.["en"];
          if (
            item.element_type === elements_type.TEXTFIELD &&
            fieldValue &&
            item.enableValidation
          ) {
            const id = item?.id;

            if (item.input_type === "string") {
              const length = fieldValue.length;

              const maxLength = Number(item?.max_length);
              const minLength = Number(item?.min_length);

              if (maxLength || minLength) {
                isFormFieldValid[id] =
                  length > maxLength
                    ? `The value must not exceed ${maxLength} characters.`
                    : length < minLength
                    ? `The value must be at least ${minLength} characters.`
                    : null;
              }
              return;
            } else {
              const value = Number(formState?.[item.id]?.value?.[locale]);
              // const enValue = Number(formState?.[item.id]?.value?.["ar"]);
              // const arValue = Number(formState?.[item.id]?.value?.["en"]);
              const maxValue = Number(item?.max_value);
              const minValue = Number(item?.min_value);

              if (maxValue || minValue) {
                isFormFieldValid[id] =
                  value > maxValue
                    ? `The value must not exceed ${maxValue}`
                    : value < minValue
                    ? `The value must be at least ${maxValue}`
                    : null;
              }
              return;
            }
          }

          if (item.required) {
            isFormFieldValid[item?.id] = fieldValue ? null : "Required";
          }
        } else {
          if (!item.required) {
            isFormFieldValid[item?.id] = null;
          }
          if (item.required && !fieldValue) {
            isFormFieldValid[item?.id] = "Required";
          }
          if (item.required && fieldValue) {
            isFormFieldValid[item?.id] = null;
          }
        }
      });

    setformError(isFormFieldValid);
    return Object.values(isFormFieldValid).every((i) => i === null);
  };

  const isFormValid = useMemo(isFormValidFunction, [formData, formState]);

  const fetchAllColumnValues = async () => {
    try {
      const filteredData = formData.filter(
        (item: any) =>
          item.element_type === elements_type.CASCADINGDROPDOWN &&
          !item?.dropdownDetails?.parentId &&
          item?.dropdownDetails?.tableId
      ); // Exclude items with parentId === true

      const results = await Promise.all(
        filteredData.map(async (item: any) => {
          const res = await fetchColumnValues(item?.dropdownDetails?.tableId);
          return {
            tableId: item?.dropdownDetails?.tableId,
            options: res,
            id: item.id,
          };
        })
      );
      setCascadingOptions((prev: any) => {
        const updatedOptions = results.reduce(
          (acc, { tableId, options, id }, index) => {
            acc[id] = { ...prev[id], options };
            return acc;
          },
          {} as any
        );

        return { ...prev, ...updatedOptions };
      });
    } catch (error) {
      console.error("Error fetching column values:", error);
    }
  };

  const onChangeParentCascading = (field: any, value: any) => {
    let isChildElement = formData.find(
      (item: any) => field.id === item.dropdownDetails.parentId
    );

    if (isChildElement?.id) {
      GetColumnValuesByParent(
        isChildElement?.dropdownDetails?.tableId,
        value,
        isChildElement?.id
      );
    }
  };

  const GetColumnValuesByParent = async (
    tableId: string,
    value: string,
    id: string
  ) => {
    try {
      const res = await fetchColumnValuesByParent(tableId, value);
      setCascadingOptions((prev: any) => ({ ...prev, [id]: { options: res } }));
    } catch (error) {}
  };

  useEffect(() => {
    fetchAllColumnValues();
  }, [formData]);

  const dependentConditions = (field: any) => {
    console.log(field);
    const parentValue = formState?.[field?.parentId]?.value?.[locale];
    console.log(parentValue);

    switch (field.condition) {
      case "equals":
        return parentValue === field.value;
      case "contains":
        return parentValue?.includes?.(field.value);
      case "greaterThan":
        return parentValue > field.value;
      case "lessThan":
        return parentValue < field.value;
      default:
        return false;
    }
  };
  return (
    // <div className="popup-overlay">
    //   <div className="popup-content">
    //     <button className="close-button" onClick={onClose}>
    //       ×
    //     </button>
    //     <h2>{formData.name}</h2>
    //     <p>{formData.description}</p>
    <div>
      {formData?.map((field: any, index: number) => {
        const isShowDependenField =
          !field?.enableDependent ||
          (field.enableDependent &&
            dependentConditions(field?.dependentDetails ?? {}));

        return (
          <Grid container spacing={2}>
            <PreviewElements
              field={field}
              handleChange={handleChange}
              index={index}
              formContainerWidth={700}
              formState={formState}
              formError={formError}
              formErrorMessage={formErrorMessage}
              isSubmitted={isSubmitted}
              addToRefs={addToRefs}
              cascadingOptions={cascadingOptions}
              setCascadingOptions={setCascadingOptions}
              onChangeParentCascading={onChangeParentCascading}
              isShowDependenField={isShowDependenField}
              lang={locale}
              isPreview={isPreview}
              previewActiveLang={previewActiveLang}
            ></PreviewElements>
          </Grid>
        );
      })}
      {/* 
                <div className='form-group' style={{
                    flexDirection: "column"
                }}>
                <p style={{marginBottom: "5px"}}>Please select the action to be taken: </p>
                    <select id="action-select" value={selectedAction} onChange={handleActionChange} style={{padding: "10px", width: "100%"}}>
                        <option value="">Select an action</option>
                        {actionList?.map((action, index) => (
                            <option key={index} value={action.uuid}>{action.name}</option>
                        ))} 
                    </select>
                    
                </div> */}

      {!isPreview && (
        <div className="relative">
          <label className="block text-gray-700 font-semibold mb-2 flex items-center gap-2">
            <FormattedMessage id="selectanActionInbox" />
            <span className="px-2 py-0.5 bg-blue-100 text-blue-700 text-xs rounded-full">
              <FormattedMessage id="requiredErrorMessage" />
            </span>
          </label>
          <div className="relative">
            {/* Pulsing border effect */}
            <div className="absolute -inset-0.5 bg-blue-200 rounded-lg animate-pulse"></div>
            {/* Dropdown with special styling */}
            <select
              value={selectedAction}
              onChange={handleActionChange}
              className="relative w-full p-3 bg-white border-2 border-blue-500 rounded-lg 
                          text-gray-800 font-medium cursor-pointer
                          shadow-sm hover:border-blue-600 hover:bg-blue-50
                          focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50
                          transition-all duration-200
                          appearance-none" // Added this class to remove default arrow
            >
              <option value="">
                <FormattedMessage id={"selectanActionInbox"} />
              </option>
              {actionList[0]?.map((action: any, index: number) => (
                <option key={index} value={action.uuid}>
                  {action.label}
                </option>
              ))}
            </select>
            {/* Helper arrow indicator */}
            <div className="absolute top-1/2 transform -translate-y-1/2 pointer-events-none rtl:left-3 rtl:right-unset ltr:right-3 ltr:left-unset">
              <i
                className={`fas fa-chevron-down text-blue-400 hover:text-blue-800`}
              ></i>
            </div>
          </div>
          {/* Helper text */}
          <p
            className={`mt-2 text-sm text-left ${
              isSubmitted && !selectedAction ? "text-red-600" : "text-blue-600"
            } rtl:text-right`}
          >
            <FormattedMessage id={"actionLabelInbox"} />
          </p>
        </div>
      )}
      <div className="button-container">
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={loader}
          style={{
            textTransform: "capitalize",
          }}
        >
          <FormattedMessage
            id={isPreview ? "submitButton" : "ForwardInboxButton"}
          />
        </Button>
      </div>
    </div>
  );
};

export default React.memo(DynamicFormPopup);

const PreviewElements = ({
  field,
  index,
  formContainerWidth = 700,
  handleChange,
  formState,
  formError,
  addToRefs,
  formErrorMessage,
  isSubmitted,
  lang,
  cascadingOptions,
  setCascadingOptions,
  onChangeParentCascading,
  isShowDependenField,
  isPreview,
  previewActiveLang,
}: any) => {
  const { locale } = useIntl();

  const activeLanguage = isPreview ? previewActiveLang : locale;
  const fieldWidth = field?.width || 100;
  function removePercentage(percentage: any) {
    const result = 12 * (1 - percentage / 100); // Adjusted formula
    return 12 - result;
  }

  const pixelValue = (percentage = 0) => {
    const widthPercentage = percentage ? percentage : 100;
    return (widthPercentage / 100) * ((formContainerWidth ?? 0) - 40);
  };

  const fieldContainerStyle = {
    marginBottom: "20px", // Add margin to separate form fields
  };

  const Label =
    field?.translate?.[activeLanguage]?.label ||
    field?.label?.[activeLanguage]?.label;
  const Placeholder =
    field?.translate?.[activeLanguage]?.placeholder ||
    field?.label?.[activeLanguage]?.placeholder;
  const value = formState?.[field.id]?.value?.[activeLanguage];

  const options =
    field?.translate?.[activeLanguage]?.options?.map((x: any) => ({
      label: x.label ?? "",
      value: x.label ?? "",
    })) || [];

  return (
    <Grid
      item
      key={field.id}
      md={
        field?.element_type === elements_type.TITLE
          ? 12
          : removePercentage(fieldWidth)
      }
    >
      {isShowDependenField &&
        (() => {
          switch (field?.element_type) {
            case elements_type.TITLE:
              return (
                <Typography
                  textAlign="center"
                  variant="h3"
                  sx={{
                    color: "#000",
                    marginBottom: "15px",
                    width: "100%",
                  }}
                >
                  {field?.value || ""}
                </Typography>
              );
            case elements_type.TEXTFIELD:
              return (
                <InputField
                  type={field?.input_type !== "string" ? "number" : undefined}
                  step={field?.input_type === "float" ? "0.01" : undefined}
                  label={(Label || "Label") + (field?.required ? " *" : "")}
                  placeHolder={Placeholder}
                  onChange={(value) => handleChange(field, value)}
                  id=""
                  value={value ?? ""}
                  name={field?.id}
                  error={isSubmitted && formError?.[field.id]}
                  helperText={
                    isSubmitted && formError?.[field.id]
                      ? formError?.[field.id]
                      : ""
                  }
                  min={
                    field.enableValidation &&
                    (field.input_type === "number" ||
                      field.input_type === "float")
                      ? field.min_value
                      : undefined
                  }
                  max={
                    field.enableValidation &&
                    (field.input_type === "number" ||
                      field.input_type === "float")
                      ? field.max_value
                      : undefined
                  }
                  minlength={
                    field.enableValidation && field.input_type === "string"
                      ? field.min_length
                      : undefined
                  }
                  maxlength={
                    field.enableValidation && field.input_type === "string"
                      ? field.max_length
                      : undefined
                  }
                />
              );
            case elements_type.CHECKBOX:
              return (
                <Box>
                  <div className="flex items-center">
                    <input
                      // checked={formState?.[field.id]?.value ? true : false}
                      id="checked-checkbox"
                      type="checkbox"
                      onChange={(e) => {
                        handleChange(field, e.target.checked);
                      }}
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <Typography
                      variant="subtitle1"
                      textTransform={"capitalize"}
                      ml={1}
                    >
                      {(Label || "Label") + (field.required ? " *" : "")}
                    </Typography>
                    {/* 
                          htmlFor="checked-checkbox"
                          className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-900"
                        >
                          {label}
                        </label> */}
                  </div>
                  {/* <CheckBox
                    label={
                      (field?.label || "Label") + (field.required ? " *" : "")
                    }
                    onChange={(e) => handleChange(field , e.target.checked)}
                    isChecked={!!formState?.[field.id]?.value}              
                  /> */}
                </Box>
              );
            case elements_type.DROPDOWN:
              return (
                <>
                  <Dropdown
                    label={(Label || "Label") + (field.required ? " *" : "")}
                    options={options}
                    value={value ?? ""}
                    name={field.id}
                    onChange={(e) => handleChange(field, e.target.value)}
                  />
                  {isSubmitted && formError?.[field.id] && (
                    <FormHelperText
                      error={true}
                      sx={{ direction: "inherit", textAlign: "inherit" }}
                    >
                      <>{formError[field.id] && formError?.[field.id]}</>
                    </FormHelperText>
                  )}
                </>
              );
            case elements_type.RADIOBUTTON:
              return (
                <FormControl style={fieldContainerStyle}>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="female"
                  >
                    {field?.options?.map((element: any) => (
                      <FormControlLabel
                        key={element.value}
                        value={element.value}
                        control={<Radio />}
                        label={element.label}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              );
            case elements_type.DATE:
              return (
                <>
                  <DatePickerCustom
                    label={(Label || "Label") + (field?.required ? " *" : "")}
                    value={formState?.[field.id]?.value ?? ""}
                    name={field.id}
                    onChange={(e) => handleChange(field, e.target.value)}
                    min={field.enableValidation ? field?.start_date : undefined}
                    max={field.enableValidation ? field?.end_date : undefined}
                  />
                  {isSubmitted && formError?.[field.id] && (
                    <FormHelperText
                      error={true}
                      sx={{ direction: "inherit", textAlign: "inherit" }}
                    >
                      <>{formError[field.id] && formError?.[field.id]}</>
                    </FormHelperText>
                  )}
                </>
              );

            case elements_type.DATE_TIME:
              return (
                <>
                  <DateTimePickerCustom
                    label={(Label || "Label") + (field?.required ? " *" : "")}
                    value={formState?.[field.id]?.value ?? ""}
                    name={field.id}
                    onChange={(e) => handleChange(field, e.target.value)}
                    min={field.enableValidation ? field?.start_date : undefined}
                    max={field.enableValidation ? field?.end_date : undefined}
                  />
                  {isSubmitted && formError?.[field.id] && (
                    <FormHelperText
                      error={true}
                      sx={{ direction: "inherit", textAlign: "inherit" }}
                    >
                      <>{formError[field.id] && formError?.[field.id]}</>
                    </FormHelperText>
                  )}
                </>
              );
            case elements_type.TOGGLE:
              return (
                <FormControlLabel
                  label={
                    <Typography variant="subtitle1" textTransform="capitalize">
                      {field.label}
                    </Typography>
                  }
                  control={
                    <Switch
                      defaultChecked={field?.defaultChecked}
                      checked={!!formState?.[field.id]?.value}
                      onChange={(e, checked) => handleChange(field, checked)}
                    />
                  }
                  style={{ fontWeight: "500", color: "black" }}
                />
              );
            case elements_type.FILEUPLOAD:
              return (
                <>
                  <FileUpload
                    label={(Label || "Label") + (field.required ? " *" : "")}
                    value={formState?.[field.id]?.value ?? ""}
                    name={field.id}
                    onChange={(e) =>
                      handleChange(field, e?.target?.files?.[0], e)
                    }
                  />
                  {isSubmitted && formError?.[field.id] && (
                    <FormHelperText
                      error={true}
                      sx={{ direction: "inherit", textAlign: "inherit" }}
                    >
                      <>{formError[field.id] && formError?.[field.id]}</>
                    </FormHelperText>
                  )}
                </>
              );
            case elements_type.MULTIFILEUPLOAD:
              return (
                <>
                  <MultiFileUpload
                    label={(Label || "Label") + (field.required ? " *" : "")}
                    value={formState?.[field.id]?.value ?? []}
                    name={field.id}
                    onChange={(e) => handleChange(field, e?.target?.files, e)}
                  />
                  {isSubmitted && formError?.[field.id] && (
                    <FormHelperText
                      error={true}
                      sx={{ direction: "inherit", textAlign: "inherit" }}
                    >
                      <>{formError[field.id] && formError?.[field.id]}</>
                    </FormHelperText>
                  )}
                </>
              );
            case elements_type.MULTISELECTDROPDOWN:
              return (
                <>
                  <MultiSelectField
                    label={
                      (field?.label || "Label") + (field.required ? " *" : "")
                    }
                    options={options || []}
                    value={value && Array.isArray(value) ? value : []}
                    onChange={(e: any) => handleChange(field, e.target.value)}
                    name={field.id}
                  />
                  {isSubmitted && formError?.[field.id] && (
                    <FormHelperText
                      error={true}
                      sx={{ direction: "inherit", textAlign: "inherit" }}
                    >
                      <>{formError[field.id] && formError?.[field.id]}</>
                    </FormHelperText>
                  )}
                </>
              );

            case elements_type.DIGITASIGNATURE:
              return (
                <>
                  <div>
                    {field.label && (
                      <Typography
                        variant="subtitle1"
                        textTransform={"capitalize"}
                        mb={1}
                      >
                        {field.label}{" "}
                      </Typography>
                    )}
                    <div
                      style={{
                        backgroundColor: "#f5f5f5",
                        width: pixelValue(field.width),
                      }}
                    >
                      <SignatureCanvas
                        ref={(el) => addToRefs(el, field.id)}
                        penColor="green"
                        canvasProps={{
                          width: pixelValue(field.width),
                          height: 200,
                          className: "sigCanvas",
                        }}
                      />
                    </div>
                  </div>
                  {/* {isSubmitted && formError?.[field.id] && (
                  <FormHelperText
                    error={true}
                    sx={{ direction: "inherit", textAlign: "inherit" }}
                  >
                    <>{formError[field.id] && formError?.[field.id]}</>
                  </FormHelperText>
                )} */}
                </>
              );
            case elements_type.GROUPFIELDS:
              return (
                <div className="mt-2">
                  {/* {JSON.stringify(field.fields)} */}
                  <Typography
                    textAlign="center"
                    variant="h3"
                    sx={{
                      fontSize: "18px",
                      color: "#000",
                      marginBottom: "16px",
                      width: "100%",
                    }}
                  >
                    {field?.title || ""}
                  </Typography>
                  {field.fields?.length > 0 ? (
                    <Grid container spacing={2}>
                      {field?.fields?.map((field: any, index: number) => {
                        return (
                          <PreviewElements
                            field={field}
                            index={index}
                            formContainerWidth={formContainerWidth}
                            handleChange={handleChange}
                            formState={formState}
                            formError={formError}
                            formErrorMessage={formErrorMessage}
                            isSubmitted={isSubmitted}
                          />
                        );
                      })}
                    </Grid>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        padding: "20px",
                        color: "#555",
                        backgroundColor: "#f9f9f9",
                        borderRadius: "8px",
                        margin: "20px 0",
                      }}
                    >
                      <InfoIcon
                        style={{
                          fontSize: 50,
                          marginBottom: "10px",
                          color: "#90caf9",
                        }}
                      />
                      <Typography
                        variant="h6"
                        textAlign="center"
                        color="textSecondary"
                      >
                        No fields have been added to this form group.
                      </Typography>
                    </div>
                  )}
                </div>
              );

            case elements_type.CASCADINGDROPDOWN:
              return (
                <>
                  <Dropdown
                    label={(Label || "Label") + (field.required ? " *" : "")}
                    options={cascadingOptions?.[field?.id]?.options ?? []}
                    labelKey="value"
                    valueKey="id"
                    value={value ?? ""}
                    name={field.id}
                    onChange={(e) => {
                      handleChange(field, e.target.value);
                      onChangeParentCascading(field, e.target.value);
                    }}
                  />
                  {isSubmitted && formError?.[field.id] && (
                    <FormHelperText
                      error={true}
                      sx={{ direction: "inherit", textAlign: "inherit" }}
                    >
                      <>{formError[field.id] && formError?.[field.id]}</>
                    </FormHelperText>
                  )}
                </>
              );
            case elements_type.LOCATION:
              return (
                <Paper sx={{ padding: 2 }} elevation={0}>
                  <Typography variant="subtitle1" gutterBottom>
                    {"Location"}
                  </Typography>
                  <OpenLayersMap height="300px" width="100%" />
                </Paper>
              );
            default:
              return null;
          }
        })()}
    </Grid>
  );
};
