import { useEffect, useState } from "react";
import { BsEye } from "react-icons/bs";
import { FormattedMessage } from "react-intl";
import {
  fetchAdminProcessList,
  fetchCategories,
  fetchUserGroupByID,
  fetchUserGroups,
} from "../../../apis/administration";
import { CircularProgress, Grid } from "@mui/material";
import { formattedDate } from "../../../utils/constants";
import DataTable from "../../../components/DataTable/dataTable";
import DialogCustomized from "../../../components/Dialog/DialogCustomized";
import toast from "react-hot-toast";
import NoResults from "../../../components/NoResults";

type ObjType = {
  id: number;
  uuid: string;
  name: string;
  description: string;
  remarks: string;
  icon: string | null;
  is_active: boolean;
  on_confirmation: boolean;
  created_at: string;
  updated_at: string;
  created_by: number;
  category: number;
};

const UserGroupAdministration = () => {
  const [dataList, setDataList] = useState<ObjType[]>([]);
  const [loader, setLoader] = useState(true);
  const [groupLoader, setGroupLoader] = useState(false);

  const [selectedGroup, setSelectedGroup] = useState(null);

  useEffect(() => {
    fetchList();
  }, []);

  const fetchList = async () => {
    try {
      const res = await fetchUserGroups();
      setDataList(res ?? []);
      setLoader(false);
    } catch (err) {
      setLoader(false);
    }
  };

  const handleView = async (id: any) => {
    // setSelectedGroup(id);
    // try {
    //   const res = await fetchUserGroupByID(id)
    //   setSelectedGroup(res)
    // } catch (error: any) {
    //   toast.error(error?.message ?? "")
    //   console.log(error)
    // }
  };

  return (
    <div className="space-y-4 p-4">
      <div className="flex items-center justify-between border-b pb-4">
        <div>
          <h1 className="text-2xl font-bold text-gray-900">
            <FormattedMessage id="headingUserGroupManagement"></FormattedMessage>
          </h1>
          <p className="mt-1 text-sm text-gray-500">
            <FormattedMessage id="subHeadingUserGroupManagement"></FormattedMessage>
          </p>
        </div>
      </div>

      <Grid container spacing={2}>
        {!loader && (!Array.isArray(dataList) || dataList.length === 0) && (
          <div className="flex items-center justify-center w-full h-[calc(100vh_-_200px)]">
            <NoResults></NoResults>
          </div>
        )}
        {loader ? (
          <div className="flex items-center justify-center w-full h-[calc(100vh_-_200px)]">
            <CircularProgress />
          </div>
        ) : (
          dataList &&
          Array.isArray(dataList) &&
          dataList?.map((item) => (
            <Grid item md={3} lg={3} xs={12}>
              <div className="w-full bg-white rounded-lg shadow-md">
                <div className="p-6">
                  <div className="flex items-center justify-between">
                    <div>
                      <h3 className="text-lg font-semibold text-gray-900">
                        {item?.name}
                      </h3>
                    </div>
                    <button
                      onClick={() => handleView(item?.id)}
                      className="inline-flex items-center gap-2 px-3 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      <BsEye className="w-4 h-4" />
                      <FormattedMessage id="view" />
                    </button>
                  </div>
                </div>
              </div>
            </Grid>
          ))
        )}
      </Grid>

      <DialogCustomized
        title={"Group Details"}
        open={!!selectedGroup}
        content={<div>User groups</div>}
        handleClose={() => setSelectedGroup(null)}
      ></DialogCustomized>
    </div>
  );
};

export default UserGroupAdministration;
