const arMessages = {
  menu: "القائمة",
  workFlowBuilder: "منشئ سير العمل",
  sidemenuDashboard: "لوحة التحكم",
  sidemenuFlowBuilder: "منشئ التدفق",
  sidemenuFlowBuilderV2: "منشئ التدفق V2",
  sidemenuHeirarchy: "الهيكل",
  sidemenuHistory: "السجل",
  sidemenuHome: "الرئيسية",
  sidemenuInbox: "الوارد",
  sidemenuRequests: "الطلبات",
  sidemenuProcessing: "المعالجة",
  sidemenuSettings: "الإعدادات",
  submenuDashboards: "لوحات التحكم",
  sidemenuAdmin: "الإدارة",
  submenuChartbuilder: "منشئ الرسوم البيانية",
  submenuProcessDashboard: "لوحة تحكم العمليات",
  submenuCompleted: "المكتملة",
  submenuNew: "الجديدة",
  submenuOrganization: "المنظمة",

  aiWorkflowBuilder: "منشئ سير العمل بالذكاء الاصطناعي",
  defaultName: "الاسم الافتراضي",
  defaultDesignation: "التعيين الافتراضي",
  logout: "تسجيل الخروج",

  downloadDocuments: "تحميل المستندات",
  startProcess: "بدء العملية",
  requiredDocuments: "المستندات المطلوبة",

  newAutomationFlow: "تدفق الأتمتة الجديد",
  newCategory: "إنشاء فئة",
  drafts: "المسودات",
  recentlyViewed: "شوهد مؤخرًا",
  processName: "اسم العملية",
  description: "الوصف",
  category: "الفئة",
  createdBy: "تم الإنشاء بواسطة",
  status: "الحالة",
  actions: "الإجراءات",

  addNotification: "أضف الإشعارات إلى نظامك",
  delete: "حذف",
  selectOption: "اختر خيارًا",
  selectField: "اختر حقلًا",
  selectCondition: "اختر شرطًا",

  selectNotificationAction: "اختر إجراء",
  selectNotificationType: "اختر نوع الإشعار",
  selectNotificationMethod: "اختر طريقة الإشعار",
  selectNotificationUsers: "اختر المستخدمين",

  "New Node": "عقدة جديدة",
  addConditions: "إضافة شروط",
  addNotificationButton: "إضافة الإشعارات",
  enterValue: "أدخل القيمة",

  previewnotavailable: "لا معاينة متوفرة للعنصر المحدد",
  ForwardInboxButton: "إرسال",
  selectanActionInbox: "حدد إجراء",
  actionLabelInbox: "يرجى تحديد الإجراء المطلوب اتخاذه:",

  requiredErrorMessage: "مطلوب",

  placeholderSearch: "بحث",

  submenuAdminProcesses: "العمليات",

  adminThProcessName: "عملية",
  adminThRequestName: "اسم الطلب",
  adminThRequestId: "معرّف الطلب",
  adminThCreatedBy: "تم الإنشاء بواسطة",
  adminThCreatedDate: "تاريخ الإنشاء",
  adminThLastUpdated: "آخر تحديث",
  adminThCategory: "الفئة",
  adminThAction: "الإجراء",
  adminThName: "الاسم",

  submenuCategories: "الفئات",
  submenuUserList: "إدارة المستخدمين",
  submenuGroups: "المجموعات",

  name: "الاسم",
  remarks: "ملاحظات",

  enterName: "أدخل الاسم",
  enterDescription: "أدخل الوصف",
  enterRemarks: "أدخل الملاحظات",
  language: "اللغة",
  addLanguage: "إضافة لغة",
  additionalLanguage: "اللغة الإضافية",
  cancel: "إلغاء",

  view: "عرض",
  active: "نشط",

  unPublishedChanges: "التغييرات غير المنشورة",
  lastVersionUpdated: "أحدث إصدار مُنشر",
  publish: "نشر",
  pendingChangesMessage: "لديك تغييرات معلقة تحتاج إلى النشر",

  headingProceses: "العمليات",
  headingFlowBuilder: "منشئ التدفق",
  headingProcessDashboard: "نظرة عامة على العمليات",

  headingProcessManagement: "إدارة العمليات",
  subHeadingProcessManagement: "عرض وإدارة جميع عملياتك",

  headingRequestManagement: "إدارة الطلبات",
  subHeadingRequestManagement: "عرض وإدارة جميع طلباتك",

  headingCategoryManagement: "إدارة الفئات",
  subHeadingCategoryManagement: "عرض وإدارة جميع الفئات",

  headingUserGroupManagement: "إدارة مجموعات المستخدمين",
  subHeadingUserGroupManagement: "عرض وإدارة جميع مجموعات المستخدمين",

  filtersText: "الفلاتر",
  filterDateRange: "النطاق الزمني",
  filterTo: "إلى",
  filterShowFavorite: "إظهار المفضلة فقط",
  filterCategory: "التصفية حسب الفئة",
  filterApply: "تطبيق",
  filterReset: "إعادة تعيين",

  formBuilderNoItems: "لا توجد عناصر نموذج متاحة.",
  formBuilderElementDrop: "سيتم إسقاط العنصر هنا.",

  noDataTitle: "لا توجد بيانات متاحة.",
  placeHolderTitle: "أدخل العنوان",
  labelTitle: "العنوان",

  placeHolderText: "أدخل العنصر النائب",
  labelTextPlaceholder: "العنصر النائب",

  placeHolderLabel: "أدخل التسمية",
  labelTextLabel: "التسمية",

  labelInputType: "نوع الإدخال",

  enableValidation: "تمكين التحقق",
  disableValidation: "تعطيل التحقق",

  minLength: "الحد الأدنى للطول",
  minLengthPlaceholder: "أدخل الحد الأدنى للطول",
  maxLength: "الحد الأقصى للطول",
  maxLengthPlaceholder: "أدخل الحد الأقصى للطول",
  minValue: "الحد الأدنى للقيمة",
  minValuePlaceholder: "أدخل الحد الأدنى للقيمة",
  maxValue: "الحد الأقصى للقيمة",
  maxValuePlaceholder: "أدخل الحد الأقصى للقيمة",

  regularExpression: "التعبير العادي",
  enterRegularExpression: "أدخل نمط التعبير العادي",
  showAllStages: "إظهار لجميع المراحل",
  defaultChecked: "مفعل افتراضيًا",

  optionsForDropdown: "خيارات القائمة المنسدلة",
  optionLabel: "تسمية الخيار",
  optionLabelPlaceholder: "أدخل تسمية الخيار",

  AcceptFileFormat: "تنسيق الملف المقبول",
  maxFileLabel: "الحد الأقصى لحجم الملف (بـ ميغابايت)",
  maxFilePlaceholder: "أدخل الحد الأقصى لحجم الملف",

  startDate: "تاريخ البدء",
  endDate: "تاريخ الانتهاء",

  formText: "نموذج",
  previewText: "معاينة",

  title: "عنوان",
  groupFields: "مجموعة الحقول",
  toggle: "تبديل",
  textField: "حقل نصي",
  checkBox: "مربع اختيار",
  dropDown: "قائمة منسدلة",
  multiSelectDropDown: "قائمة منسدلة متعددة",
  fileUpload: "رفع ملف",
  multiFileUpload: "رفع ملفات متعددة",
  date: "تاريخ",
  dateTime: "التاريخ والوقت",
  digitalSignature: "توقيع رقمي",

  noFieldsFormYet: "لم يتم إضافة أي حقول إلى النموذج بعد.",
  startAddingFields: "ابدأ في إضافة الحقول لإنشاء النموذج.",
  submitButton: "إرسال",
  noResults: "لم يتم العثور على نتائج",

  selectDefaultOption: "اختر خيارًا",
  English: "الإنجليزية",
  Arabic: "العربية",

  allButton: "الكل",
  favoritesButton: "المفضلة",
  from: "من",
  to: "إلى",

  clearFilter: "مسح الفلاتر",

  condtions: "الشروط",

  flowName: "اسم التدفق",
  requestId: "معرّف الطلب",
  attachment: "المرفق",
  notSpecified: "غير محدد",

  label: "التسمية",
  showForAllStages: "إظهار لجميع المراحل",
  done: "تم",
  is_organization: "هل هي منظمة؟",
  templateName: "اسم القالب",
  create: "إنشاء",
  is_global: "هل هو عالمي؟",
  noPreview: "لا يوجد معاينة متاحة",
  continue: "متابعة",
  enterLabel: "أدخل التسمية",

  radioButton: "زر الاختيار",

  submenuDepartments: "الأقسام",
  headingDeparmentManagement: "إدارة الأقسام",
  subHeadingDeparmentManagement: "عرض وإدارة جميع الأقسام",
  department: "قسم",

  newDepartment: "إنشاء القسم",

  basicInformation: "معلومات أساسية",
  branches: "الفروع",
  pricing: "التسعير",
  adminThVersion: "الإصدار",

  versions: "الإصدارات",
  addUsertoWorkFlow: "إضافة مستخدم إلى سير العمل",
  publishWorkflow: "نشر سير العمل",
  version: "الإصدار",

  designation: "التسمية",
  versionName: "اسم الإصدار",
  comments: "التعليقات",
  selectVersion: "حدد إصدارًا",
  selectTemplate: "حدد قالبًا",
  selectedFile: "الملف المحدد",

  workflow: "سير العمل",
  form: "النموذج",
  generateWorkflow: "إنشاء سير العمل",
  selectNodeToCreateForm: "حدد عقدة لإنشاء تدفق",
  generate: "إنشاء",

  cascadingDropdown: "قائمة منسدلة متتالية",
  selectTable: "اختر جدولًا",
  selectColumn: "اختر عمودًا",
  mapDropdownOptions: "تعيين خيارات القائمة المنسدلة",

  tableName: "اسم الجدول",
  viewDependent: "عرض التبعيات",
  viewColumns: "عرض الأعمدة",
  headingTable: "الجداول الرئيسية",

  columnName: "اسم العمود",
  dependentTableName: "اسم الجدول التابع",
  headingColumns: "أعمدة الجدول الرئيسي",
  headingDependentTable: "الجداول التابعة",

  fileName: "اسم الملف",
  max: "الحد الأقصى",
  supportedFiles: "تنسيقات الملفات المدعومة",
  filterDepartment: "تصفية حسب القسم",
  placeholderDeparment: "البحث عن قسم",
  placeholderCategory: "البحث عن فئة",
  createNewProcess: "إنشاء عملية جديدة",

  pagination: "ترقيم الصفحات",
  noofrows: "عدد الصفوف لكل صفحة",
  sorting: "الفرز",
  filter: "تصفية",
  tableOptions: "خيارات الجدول",
  selectedColumns: "الأعمدة المحددة",
};

export default arMessages;
