import { FC, useState } from "react";
import { FormattedMessage } from "react-intl";
import useTranslation from "../../../hooks/useTranslation";
import { elements_type } from "../constants";
import { activeLanguageData, updateTranslationData } from "../formTranslations";
import InputField from "./InputField";

interface TextProps {
  collapse: string;
  formElement: any;
  en: string;
  ar: string;
  onDelete: () => void;
  onChange: (values: {
    en?: string;
    ar?: string;
    element_type: string;
    value: string;
  }) => any;
  activeLanguage: any;
}

const Text: FC<TextProps> = ({
  collapse,
  formElement,
  en,
  ar,
  onDelete,
  onChange,
  activeLanguage,
}) => {
  const { translate } = useTranslation();
  const [localData, setLocalData] = useState({
    id: formElement.id || "",
    value: formElement.value || "",
    element_type: elements_type.TITLE,
  });
  // const [activeLanguage, setactiveLanguage] = useState<"en" | "ar" | any>("en");

  const handleChange = (value: string) => {
    const updatedData = updateTranslationData(
      localData,
      "labelTitle",
      elements_type.TITLE,
      value,
      activeLanguage
    );
    setLocalData(updatedData);
  };

  const handleBlur = () => {
    onChange({ ...localData, element_type: elements_type.TITLE });
  };

  function activeLanguageuageDataa(key: string) {
    return activeLanguageData(localData, activeLanguage, key);
  }

  return (
    <>
      <div className="p-4 bg-gray-50 border-b">
        <div>
          {localData?.value && (
            <p className="text-gray-800 text-center">{localData.value}</p>
          )}
          {!localData.value && (
            <p className="text-gray-400 text-center">
              <FormattedMessage id="noDataTitle"></FormattedMessage>
            </p>
          )}
        </div>
      </div>

      {collapse === formElement.id && (
        <div>
          <div className="p-4">
            <InputField
              value={activeLanguageuageDataa("labelTitle")}
              // label="Title (English)"
              label={translate("labelTitle")}
              placeholder={translate("placeHolderTitle")}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Text;
