import { Close } from "@mui/icons-material";
import { Button, IconButton, Stack, Tooltip } from "@mui/material";
import { memo, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { IoSaveSharp } from "react-icons/io5";
import { VscPreview } from "react-icons/vsc";
import { FormattedMessage, useIntl } from "react-intl";
import { Node } from "reactflow";
import {
  createTemplate,
  getTemplateDetails,
  getTemplates,
} from "../apis/flowBuilder";
import { BASE_URL } from "../apis/urls";
import useTranslation from "../hooks/useTranslation";
import DialogCustomized from "./Dialog/DialogCustomized";
import Dropdown from "./Dropdown/Dropdown";
import LangDropDOwn from "./Dropdown/LangDropDown";
import FormEditor from "./FormEditor/FormEditor";
import CheckBox from "./FormElements/components/CheckBox";
import FormElements from "./FormElements/FormElements";
import InputField from "./FormElements/newcompnents/InputField";
import FormPreview from "./FormPreview/FormPreview";
import { Loading } from "./Loading/Loading";

const templateTemplate = {
  name: "",
  is_organization: false,
  is_global: false,
};
interface FormBuilderProps {
  selectedNode: Node;
  onClose: () => void;
  listworkflowNode: () => any;
  formContainerWidth: number;
}

const FormBuilder: React.FC<FormBuilderProps> = ({
  selectedNode,
  onClose,
  listworkflowNode,
  formContainerWidth,
}) => {
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [loader, setLoader] = useState(true);
  const [formFields, setFormFields] = useState<Array<any>>([]);
  const [previousNodeId, setPreviousNodeId] = useState<string | null>(null); // Store previous node ID
  const { translate } = useTranslation();
  const [templateDialog, setTemplateDialog] = useState(false);
  const [templateForm, setTemplateForm] = useState<any>(templateTemplate);
  const [isLoading, setIsLoading] = useState(false);
  const [templates, setTemplates] = useState([]);
  const { locale } = useIntl();
  const [activeLanguage, setActiveLanguage] = useState<string>(locale);

  useEffect(() => {
    // Check if the node ID has changed
    if (previousNodeId !== selectedNode.id) {
      setPreviousNodeId(selectedNode.id); // Update previousNodeId
      setShowPreview(false); // Close the form
      setFormFields([]); // Clear form fields when node changes

      // Fetch new form fields for the selected node
      const fetchFormFields = async () => {
        setLoader(true);
        const response = await fetch(
          `${BASE_URL}/workflow/form-fields/${selectedNode.id}`
        );
        const data = await response.json();
        setLoader(false);
        setTemplateDialog(false);
        setFormFields(data);
      };

      fetchFormFields();
    }
  }, [selectedNode.id, previousNodeId]);

  const handlePreviewToggle = () => {
    setShowPreview((prev) => !prev);
  };

  async function getTemplate() {
    console.log("before template");

    const data = await getTemplates();
    setTemplates(data);
  }

  useEffect(() => {
    getTemplate();
  }, []);

  async function handleSaveAsTemplate() {
    try {
      setIsLoading(true);
      await createTemplate(selectedNode.id, templateForm);
      toast.success(templateForm.name + " created successfully");
      setTemplateDialog(false);
      getTemplates();
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  }

  function handleTemplateFormChange(name: string, value: string) {
    setTemplateForm((state: object) => ({ ...state, [name]: value }));
  }

  async function handleTemplateChange(e: any) {
    try {
      const data = await getTemplateDetails(e.target.value);
      console.log(data);
      setFormFields(data.form_data);
    } catch (e) {
      console.log(e);
    }
  }

  return (
    // <ClickAwayListener onClickAway={() => onClose()}>
    <div className="form-builder w-full h-[calc(100vh_-_113px)] flex flex-col bg-white">
      <div className="flex-grow flex border border-gray-300 overflow-y-auto overflow-x-hidden">
        <div className="flex-grow-[3] flex flex-col p-3 pt-2 border-r border-gray-00">
          <div className="flex flex-row justify-between items-center mb-2">
            <Loading open={isLoading} />
            {/* Show title as header on center */}
            <div className="font-bold">
              <Tooltip title={selectedNode?.data?.label}>
                <h4 className={`truncate`} style={{ flex: 1 }}>
                  {selectedNode?.data?.label}
                </h4>
              </Tooltip>
            </div>
            <DialogCustomized
              open={templateDialog}
              handleClose={() => setTemplateDialog(false)}
              actions={
                <Stack direction="row" spacing={2}>
                  <Button onClick={() => setTemplateDialog(false)}>
                    {translate("cancel")}
                  </Button>
                  <Button
                    variant="contained"
                    disableElevation
                    onClick={handleSaveAsTemplate}
                  >
                    {translate("create")}
                  </Button>
                </Stack>
              }
              content={
                <Stack spacing={1}>
                  <InputField
                    label={translate("templateName")}
                    value={templateForm.name}
                    onChange={(e) => handleTemplateFormChange("name", e)}
                  />
                  <Stack direction="row" spacing={2}>
                    <CheckBox
                      label={translate("is_global")}
                      isChecked={templateForm.is_global}
                      onChange={(e) =>
                        handleTemplateFormChange("is_global", e.target.checked)
                      }
                    />
                    <CheckBox
                      label={translate("is_organization")}
                      isChecked={templateForm.is_organization}
                      onChange={(e) =>
                        handleTemplateFormChange(
                          "is_organization",
                          e.target.checked
                        )
                      }
                    />
                  </Stack>
                </Stack>
              }
              title={"Create Template"}
            />

            <LangDropDOwn
              value={activeLanguage}
              handleOnClick={(e) => setActiveLanguage(e.target.value)}
            />

            <Stack direction="row" spacing={2.5}>
              <Dropdown
                options={templates}
                labelKey="name"
                valueKey="uuid"
                onChange={handleTemplateChange}
                name={"template"}
              />
              <Tooltip title={translate("saveAsTemplate")}>
                <IconButton
                  color="primary"
                  size="small"
                  onClick={() => setTemplateDialog(true)}
                >
                  <IoSaveSharp />
                </IconButton>
              </Tooltip>

              <Button
                onClick={handlePreviewToggle}
                size="small"
                variant="text"
                sx={{
                  textTransform: "capitalize",
                }}
                startIcon={<VscPreview />}
              >
                {showPreview ? (
                  <FormattedMessage id="formText"></FormattedMessage>
                ) : (
                  <FormattedMessage id="previewText"></FormattedMessage>
                )}
              </Button>
              <IconButton size="small" onClick={onClose}>
                <Close
                  style={{
                    fontSize: "16px",
                  }}
                />
              </IconButton>
            </Stack>
          </div>
          <div className="flex-grow border-[1px] rounded-md border-gray-300 bg-[#f6f8fa] max-h-full overflow-auto">
            {showPreview === false ? (
              formFields.length === -1 ? (
                <div className="text-center text-gray-500">
                  <FormattedMessage id="formBuilderNoItems"></FormattedMessage>
                </div>
              ) : (
                <FormEditor
                  formContainerWidth={formContainerWidth}
                  updateFormData={(data) => {
                    setFormFields(data);
                  }}
                  initialData={formFields}
                  selectedNodeId={selectedNode.id}
                  loader={loader}
                  activeLanguage={activeLanguage}
                />
              )
            ) : (
              <FormPreview
                nodeId={selectedNode.id}
                formFields={formFields}
                formContainerWidth={formContainerWidth}
                activeLanguage={activeLanguage}
              />
            )}
          </div>
        </div>
        <div className="min-w-[80px]">
          <FormElements />
        </div>
      </div>
    </div>
    // </ClickAwayListener>
  );
};

export default memo(FormBuilder);
