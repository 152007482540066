const BACKGROUND_OPACITY = 'CC'; //20%

export const backgroundColors: { [key: string]: string } = {
    sky: '#D3E3FF' + BACKGROUND_OPACITY,
    lightBlue: '#A3C9FF' + BACKGROUND_OPACITY,
    lightPurple: '#E9CEFF' + BACKGROUND_OPACITY,
    deepPurple: '#A259FF' + BACKGROUND_OPACITY,
    orange: '#FBAB32' + BACKGROUND_OPACITY,
    lime: '#CFEF72' + BACKGROUND_OPACITY,
}

export const formattedDate = (date: any) => {
    const dateString = new Date(date);
    const formattedDate = dateString.toLocaleDateString("en-US", {
      month: "short", 
      year: "numeric",
      day: "2-digit", 
    });
    return formattedDate
  };