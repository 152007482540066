import { useEffect, useState } from "react";
import { BsEye } from "react-icons/bs";
import { FormattedMessage } from "react-intl";
import { fetchAdminRequestList } from "../../../apis/administration";
import { formattedDate } from "../../../utils/constants";
import { Link } from "react-router-dom";
import DataTable from "../../../components/DataTable/dataTable";
import { Button } from "@mui/material";
import { RemoveRedEyeOutlined } from "@mui/icons-material";

type ObjType = {
  id: number;
  uuid: string;
  name: string;
  description: string;
  remarks: string;
  icon: string | null;
  is_active: boolean;
  on_confirmation: boolean;
  created_at: string;
  updated_at: string;
  created_by: number;
  category: number;
  request_id: string;
};

const RequestAdministration = () => {
  const [requestList, setRequestList] = useState<ObjType[]>([]);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    fetchList();
  }, []);

  const fetchList = async () => {
    try {
      const res = await fetchAdminRequestList();
      setRequestList(res ?? []);
      setLoader(false);
    } catch (err) {
      setLoader(false);
    }
  };

  const columns = [
    { key: "request_id", label: <FormattedMessage id="adminThRequestId" /> },
    { key: "created_by", label: <FormattedMessage id="adminThCreatedBy" /> },
    {
      key: "created_at",
      label: <FormattedMessage id="adminThCreatedBy" />,
      render: (value: any) => formattedDate(value),
    },
    {
      key: "updated_at",
      label: <FormattedMessage id="adminThCreatedDate" />,
      render: (value: any) => formattedDate(value),
    },
    {
      key: "action",
      label: <FormattedMessage id="adminThAction" />,
      render: (_: any, row: any) => (
        <Button
        startIcon={<RemoveRedEyeOutlined />}
        className="rtl:gap-[10px]"
        sx={{ textTransform: "capitalize" }}
      >
        <FormattedMessage id="view" />
      </Button>
      ),
    },
  ];

  return (
    <div className="space-y-4 p-4">
      <div className="flex items-center justify-between border-b pb-4">
        <div>
          <h1 className="text-2xl font-bold text-gray-900">
            <FormattedMessage id="headingRequestManagement"></FormattedMessage>
          </h1>
          <p className="mt-1 text-sm text-gray-500">
            <FormattedMessage id="subHeadingRequestManagement"></FormattedMessage>
          </p>
        </div>
      </div>
      <DataTable columns={columns} data={requestList} loading={loader}></DataTable>
    </div>
  );
};

export default RequestAdministration;
