import { Settings } from "@mui/icons-material";
import {
  Box,
  ButtonBase,
  Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  Switch,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { fetchTableList } from "../../../apis/flowBuilder";
import useTranslation from "../../../hooks/useTranslation";
import DialogCustomized from "../../Dialog/DialogCustomized";
import Dropdown from "../../Dropdown/Dropdown";
import InputField from "./InputField";

const iconMap = {
  pagination: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={12.5}
      height={12}
      viewBox="0 0 25 24"
    >
      <path
        fill="currentColor"
        d="M2.72 11.47a.75.75 0 0 0 0 1.06l4 4a.75.75 0 0 0 1.06-1.06L4.31 12l3.47-3.47a.75.75 0 1 0-1.06-1.06zm19.56 1.06a.75.75 0 0 0 0-1.06l-4-4a.75.75 0 1 0-1.06 1.06L20.69 12l-3.47 3.47a.75.75 0 1 0 1.06 1.06z"
        strokeWidth={0.2}
        stroke="currentColor"
      ></path>
      <path
        fill="currentColor"
        d="M8.5 11.1a.9.9 0 1 0 0 1.8a.9.9 0 0 0 0-1.8m3.1.9a.9.9 0 1 1 1.8 0a.9.9 0 0 1-1.8 0m4 0a.9.9 0 1 1 1.8 0a.9.9 0 0 1-1.8 0"
        strokeWidth={0.2}
        stroke="currentColor"
      ></path>
    </svg>
  ),
  sorting: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={12}
      height={12}
      viewBox="0 0 24 24"
    >
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.7}
        d="m15.937 2l.48.459c.43.41.644.614.568.788c-.075.174-.379.174-.986.174H8.566C5.491 3.421 2.886 5.345 2 8m6.021 14l-.438-.42c-.43-.413-.644-.618-.568-.793c.075-.175.379-.175.985-.175h7.432c3.078 0 5.685-1.938 6.568-4.612m-7-6.5h2.947c.62 0 .93 0 1.013.2s-.128.44-.55.92l-2.425 2.76c-.422.48-.633.72-.55.92s.392.2 1.012.2H19m-16 0l1.755-3.912C5.08 9.863 5.242 9.5 5.5 9.5s.42.363.745 1.088L8 14.5m3-2.5h1"
        color="currentColor"
      ></path>
    </svg>
  ),
  filter: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={12}
      height={12}
      viewBox="0 0 24 24"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M4.953 2.25h14.094c.667 0 1.237 0 1.693.057c.483.061.95.198 1.334.558c.39.367.545.824.613 1.299c.063.437.063.98.063 1.6v.776c0 .489 0 .91-.036 1.263c-.04.38-.125.735-.331 1.076c-.205.339-.48.585-.798.805c-.299.208-.68.423-1.13.676l-2.942 1.656c-.67.377-.903.513-1.059.648c-.357.31-.562.655-.658 1.086c-.041.185-.046.417-.046 1.123v2.732c0 .901 0 1.666-.093 2.255c-.098.625-.327 1.225-.927 1.6c-.587.367-1.232.333-1.86.184c-.605-.143-1.35-.435-2.244-.784l-.086-.034c-.42-.164-.786-.307-1.076-.457c-.312-.161-.602-.361-.823-.673c-.225-.316-.314-.654-.355-1c-.036-.315-.036-.693-.036-1.115v-2.708c0-.706-.004-.938-.046-1.123a1.93 1.93 0 0 0-.658-1.086c-.156-.135-.39-.271-1.059-.648L3.545 10.36c-.45-.253-.831-.468-1.13-.676c-.318-.22-.593-.466-.798-.805c-.206-.341-.291-.697-.33-1.076c-.037-.352-.037-.774-.037-1.263v-.776c0-.62 0-1.163.063-1.6c.068-.475.223-.932.613-1.299c.384-.36.85-.497 1.334-.558c.456-.057 1.026-.057 1.693-.057M3.448 3.796c-.334.042-.44.11-.495.163c-.05.046-.114.127-.156.418c-.045.318-.047.752-.047 1.438v.69c0 .534 0 .878.028 1.144c.026.247.07.366.124.455c.055.091.147.194.368.348c.234.162.553.343 1.04.617l2.913 1.64l.08.045c.56.315.94.529 1.226.777a3.43 3.43 0 0 1 1.14 1.893c.081.367.081.78.081 1.36v2.759c0 .472.001.762.027.98c.022.198.059.265.086.304c.03.042.09.107.289.21c.212.109.505.224.967.405c.961.376 1.608.627 2.097.743c.479.114.637.055.718.004c.068-.043.173-.13.242-.563c.072-.457.074-1.103.074-2.084v-2.758c0-.58 0-.993.082-1.36a3.43 3.43 0 0 1 1.139-1.893c.286-.248.667-.463 1.225-.777l.081-.045l2.913-1.64c.487-.274.806-.455 1.04-.617c.221-.154.313-.257.368-.348c.054-.089.098-.208.123-.455c.028-.266.029-.61.029-1.145v-.69c0-.685-.002-1.12-.047-1.437c-.042-.291-.107-.372-.155-.418c-.056-.052-.162-.121-.496-.163c-.35-.045-.825-.046-1.552-.046H5c-.727 0-1.201.001-1.552.046"
        clipRule="evenodd"
        strokeWidth={0.2}
        stroke="currentColor"
      ></path>
    </svg>
  ),
  number: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={12}
      height={12}
      viewBox="0 0 16 16"
    >
      <path
        fill="none"
        stroke="currentColor"
        d="M2 4.5h1v7m0 0H2m1 0h1m1-7h4V8H5.5v3.5h4m1-7H14V8m0 0h-3m3 0v3.5h-3.5"
        strokeWidth={1}
      ></path>
    </svg>
  ),
};

function DataGrid({ formElement, onChange, activeLanguage }: any) {
  const { translate } = useTranslation();
  const [options, setOptions] = useState([]);
  const [data, setData] = useState({
    input_type: "DataGrid",
    ...formElement,
  });
  const [openDialog, setOpenDialog] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    async function getTableList() {
      const data = await fetchTableList();
      console.log(data);
      setOptions(data);
    }
    getTableList();
  }, []);

  function changeHandler(label: string, value: string) {
    const updatedData = {
      ...data,
      [label]: value,
    };
    setData(updatedData);
    onChange(updatedData);
  }

  return (
    <div className="p-4 space-y-6">
      <Stack direction="row" spacing={1} alignItems="flex-end">
        <Dropdown
          label={translate("selectTable")}
          options={options}
          labelKey="name"
          valueKey="id"
          name=""
          onChange={(e) => changeHandler("tableId", e.target.value)}
          value={data.tableId}
        />
        <IconButton
          disabled={!data.tableId}
          onClick={() => setOpenDialog(true)}
        >
          <Settings />
        </IconButton>
      </Stack>

      {/* Grid Dialog */}
      <DialogCustomized
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
        title="Data Grid Configurator"
        content={
          <Grid container>
            <Grid
              item
              md={6}
              paddingRight={2}
              borderRight={`1px solid ${theme.palette.divider}`}
            >
              <InputField label={undefined} placeholder="Search..." />
              <Stack
                marginTop={1}
                spacing={0.2}
                height="60vh"
                overflow="auto"
                divider={<Divider />}
                border={`1px solid ${theme.palette.divider}`}
                borderRadius={1}
                paddingX={1}
              >
                {[1, 1, 2, 3, 3, 4, 4].map(() => (
                  <ButtonBase sx={{ justifyContent: "flex-start" }}>
                    &nbsp; &nbsp;
                    <FormControlLabel
                      sx={{ flex: 1 }}
                      control={<Checkbox defaultChecked size="small" />}
                      label={<Typography variant="caption">Label</Typography>}
                    />
                  </ButtonBase>
                ))}
              </Stack>
            </Grid>
            <Grid item md={6} paddingX={2}>
              <Typography variant="h5" mb={1.5}>
                {translate("selectedColumns")}
              </Typography>

              <Box>
                <Stack
                  direction="row"
                  flexWrap="wrap"
                  gap={1}
                  borderRadius={1}
                  padding={1}
                  border={`1px solid ${theme.palette.divider}`}
                  height={"150px"}
                  alignContent="flex-start"
                >
                  {[1, 2, 3, 5, 5].map((val, ind) => (
                    <Chip
                      key={val}
                      label={
                        <Typography variant="caption">
                          {"Line " + ind}
                        </Typography>
                      }
                      size={"small"}
                      onDelete={() => {}}
                    />
                  ))}
                </Stack>
              </Box>
              <br />
              <Typography variant="h5" mb={1.5}>
                {translate("tableOptions")}
              </Typography>

              <Stack divider={<Divider />} spacing={1} marginTop={1}>
                <TableOptionsItem icon={iconMap.pagination} text="pagination" />
                <TableOptionsItem
                  icon={iconMap.number}
                  text="noofrows"
                  number
                />
                <TableOptionsItem icon={iconMap.sorting} text="sorting" />
                <TableOptionsItem icon={iconMap.filter} text="filter" />
              </Stack>
            </Grid>
          </Grid>
        }
      />
    </div>
  );
}

export default DataGrid;

function TableOptionsItem({
  icon,
  text,
  number,
}: {
  icon: any;
  text: string;
  number?: boolean;
}) {
  const { translate } = useTranslation();
  const theme = useTheme();
  return (
    <Stack spacing={1} direction="row" alignItems="center">
      <Box
        sx={{
          ".svg": {
            width: 18,
            height: 18,
            fill: "currentColor",
          },
        }}
      >
        {icon}
      </Box>
      <Typography flex={1} variant="caption">
        {translate(text)}
      </Typography>
      {number ? (
        <input
          type="number"
          placeholder=""
          style={{
            border: `1px solid ${theme.palette.divider}`,
            width: "60px",
            paddingLeft: "5px",
            borderRadius: "4px",
          }}
        />
      ) : (
        <Switch size="small" />
      )}
    </Stack>
  );
}
