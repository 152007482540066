import { RemoveRedEyeOutlined } from "@mui/icons-material";
import { Avatar, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { fetchAdminProcessList } from "../../../apis/administration";
import Chip from "../../../components/Chip/Chip";
import DataTable from "../../../components/DataTable/dataTable";
import { formattedDate } from "../../../utils/constants";
import { useParams } from "react-router";
import { fetchProcessDetails } from "../../../apis/process";

type ObjType = {
  id: number;
  uuid: string;
  name: string;
  description: string;
  remarks: string;
  icon: string | null;
  is_active: boolean;
  on_confirmation: boolean;
  created_at: string;
  updated_at: string;
  created_by: number;
  category: number;
};

const ProcessVersionDetails = () => {
  const { processId } = useParams();

  const [processDetails, setProcessDetails] = useState<any>({});
  const [versionList, setVersionList] = useState([]);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    fetchProcessList(processId);
  }, [processId]);

  const fetchProcessList = async (processId: string = "") => {
    try {
      const res = await fetchProcessDetails(processId);
      setProcessDetails(res || {});
      setLoader(false);
    } catch (err) {
      setLoader(false);
    }
  };

  const columns = [
    {
      key: "name",
      label: <FormattedMessage id="adminThVersion" />,
      render: (value: string, row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Avatar
              src={row.icon || ""}
              sx={{ width: 42, height: 42, bgcolor: "primary.main" }}
            >
              {value?.charAt(0)}
            </Avatar>
            <span className="text-sm font-medium text-gray-700 truncate">
              {value}
            </span>
          </div>
        );
      },
    },
    { key: "created_by", label: <FormattedMessage id="adminThCreatedBy" /> },
    {
      key: "created_at",
      label: <FormattedMessage id="adminThCreatedDate" />,
      render: (value: any) => formattedDate(value),
    },
    {
      key: "updated_at",
      label: <FormattedMessage id="adminThLastUpdated" />,
      render: (value: any) => formattedDate(value),
    },
  ];

  return (
    <div className="space-y-4 p-4">
      <div className="flex items-center justify-between border-b pb-4">
        <div>
          <h1 className="text-2xl font-bold text-gray-900">
            <FormattedMessage id="versions"></FormattedMessage> -{" "}
            <span className="text-primary">{processDetails?.name}</span>
          </h1>
        </div>
      </div>
      <DataTable
        columns={columns}
        data={versionList && Array.isArray(versionList) ? versionList : []}
        loading={loader}
      ></DataTable>
    </div>
  );
};

export default ProcessVersionDetails;
