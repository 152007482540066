import { FC, useState } from "react";
import InputField from "./components/InputField";
import { elements_type } from "./constants";
import { activeLanguageData, updateTranslationData } from "./formTranslations";
import OpenLayersMap from "../../containers/map/OpenLayersMap";
import { Typography } from "@mui/material";

interface TextProps {
  onChange: (data: any, api_call: boolean) => void; // Pass api_call as a second argument
  formElement: any;
  activeLanguage: string;
  collapse: any;
}

const Location: FC<TextProps> = ({
  onChange,
  formElement,
  activeLanguage,
  collapse,
}) => {
  const [data, setData] = useState({
    input_type: elements_type.LOCATION,
    ...formElement,
  });

  const [apiCall, setApiCall] = useState(true); // State to track if API should be called

  // Handle changes in the title field
  const handleChange = (value: string) => {
    const updatedData = updateTranslationData(
      data,
      "label",
      elements_type.LOCATION,
      value,
      activeLanguage
    );
    setData(updatedData);
    onChange(updatedData, apiCall); // Pass apiCall state to the parent
  };

  function getActiveLanguageData(key: string) {
    return activeLanguageData(data, activeLanguage, key);
  }

  return (
    <>
      <div className="p-4 bg-gray-50">
        <div className="bg-white p-4 rounded-md shadow-md">
          <Typography variant="subtitle1" gutterBottom>
            {getActiveLanguageData("label") || "Location"}
          </Typography>
          <OpenLayersMap height="100px" width="100%" />
        </div>
      </div>

      {collapse && (
        <div className="border-[1px] rounded-md bg-white">
          <div className="p-4">
            <InputField
              value={getActiveLanguageData("label")}
              label="Label"
              id=""
              placeHolder={data.placeholder || "Label"}
              // Set apiCall to false before calling handleChange in onChange event
              onChange={(value: string) => {
                setApiCall(false);
                handleChange(value);
              }}
              // Set apiCall to true before calling handleChange in onBlur event
              onBlur={(e) => {
                setApiCall(true);
                handleChange(e.target.value);
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Location;
