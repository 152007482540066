import {
  MdOutlineArrowDropDownCircle,
  MdOutlineCalendarToday,
  MdOutlineCheckBox,
  MdOutlineFileUpload,
  MdOutlineToggleOn,
  MdTextFields,
  MdTitle,
  MdViewList,
  MdDateRange,
  MdRadioButtonChecked,
} from "react-icons/md";

import { FaTable } from "react-icons/fa";

import { ImUpload2 } from "react-icons/im";
import { FaObjectGroup } from "react-icons/fa";
import { TbSignature } from "react-icons/tb";
import { FaMapMarkedAlt } from "react-icons/fa";

export const elements_type = {
  TITLE: "TITLE",
  GROUPFIELDS: "GROUPFIELDS",
  TEXTFIELD: "TEXT_FIELD",
  CHECKBOX: "CHECKBOX",
  DROPDOWN: "DROP_DOWN",
  FILEUPLOAD: "FILE_UPLOAD",
  DATE: "DATE",
  DATE_TIME: "DATE_TIME",
  RADIOBUTTON: "RADIO_BUTTON",
  MULTIFILEUPLOAD: "MULTI_FILE_UPLOAD",
  MULTISELECTDROPDOWN: "MULTISELECTDROPDOWN",
  TOGGLE: "TOGGLE",
  STAGE: "STAGE",
  DIGITASIGNATURE: "DIGITAL_SIGNATURE",
  CASCADINGDROPDOWN: "CASCADINGDROPDOWN",
  LOCATION: "Location",
  DATAGRID: "DataGrid",
};

export const elements = [
  {
    type: elements_type.TITLE,
    label: "title",
    icon: MdTitle,
  },
  {
    type: elements_type.GROUPFIELDS,
    label: "groupFields",
    icon: FaObjectGroup,
  },
  {
    type: elements_type.TOGGLE,
    label: "toggle",
    icon: MdOutlineToggleOn,
  },
  {
    type: elements_type.TEXTFIELD,
    label: "textField",
    icon: MdTextFields,
  },
  {
    type: elements_type.RADIOBUTTON,
    label: "radioButton",
    icon: MdRadioButtonChecked,
  },
  {
    type: elements_type.TEXTFIELD,
    label: "textField",
    icon: MdTextFields,
  },
  {
    type: elements_type.CHECKBOX,
    label: "checkBox",
    icon: MdOutlineCheckBox,
  },
  {
    type: elements_type.DROPDOWN,
    label: "dropDown",
    icon: MdOutlineArrowDropDownCircle,
  },
  {
    type: elements_type.MULTISELECTDROPDOWN,
    label: "multiSelectDropDown",
    icon: MdViewList,
  },
  {
    type: elements_type.FILEUPLOAD,
    label: "fileUpload",
    icon: MdOutlineFileUpload,
  },
  {
    type: elements_type.MULTIFILEUPLOAD,
    label: "multiFileUpload",
    icon: ImUpload2,
  },
  {
    type: elements_type.DATE,
    label: "date",
    icon: MdOutlineCalendarToday,
  },
  {
    type: elements_type.DATE_TIME,
    label: "dateTime",
    icon: MdDateRange,
  },
  {
    type: elements_type.DIGITASIGNATURE,
    label: "digitalSignature",
    icon: TbSignature,
  },
  {
    type: elements_type.CASCADINGDROPDOWN,
    label: "cascadingDropdown",
    icon: MdViewList,
  },
  {
    type: elements_type.LOCATION,
    label: "Location",
    icon: FaMapMarkedAlt,
  },
  {
    type: elements_type.DATAGRID,
    label: "Data Grid",
    icon: FaTable,
  },
];
