import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import useTranslation from "../../../hooks/useTranslation";

interface MultiFileUploadProps {
  name: string;
  label?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  prepend?: React.ReactNode;
  value?: string[] | null;
  disabled?: boolean;
}

const MultiFileUpload: React.FC<MultiFileUploadProps> = ({
  name,
  label,
  onChange,
  prepend,
  value,
  disabled = false,
}) => {
  console.log(value);
  const [fileNames, setFileNames] = useState<any[]>(
    value ? value?.map((file: any) => file?.name) : []
  );
  const { translate } = useTranslation();

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // const files = e.target.files ? Array.from(e.target.files) : [];
    onChange(e); // Calling the onChange function passed as a prop
  };

  useEffect(() => {
    setFileNames(value?.map((file: any) => file?.name) ?? []);
  }, [value]);

  return (
    <div className="flex flex-col w-full">
      {label && (
        <Typography variant="subtitle1" textTransform={"capitalize"} mb={1}>
          {label}
        </Typography>
      )}
      <div
        className={`shadow appearance-none border rounded w-full overflow-hidden flex items-center ${
          prepend ? "pl-2" : ""
        }`}
      >
        {prepend && <span className="mr-2">{prepend}</span>}
        <input
          disabled={disabled}
          type="file"
          id={name}
          name={name}
          onChange={handleFileChange}
          className="w-full p-2 text-base text-gray-700 bg-white focus:outline-none"
          accept="*"
          multiple // Enable multiple file selection
        />
      </div>
      {fileNames.length > 0 && (
        <div className="mt-2 text-sm text-gray-600">
          <strong>{translate("selectedFile")}:</strong>
          <ul className="list-disc pl-5">
            {fileNames.map((fileName, index) => (
              <li key={index}>{fileName}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default MultiFileUpload;
