// ProcessList Component

import { HelpOutline } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { MdFavorite, MdFavoriteBorder } from "react-icons/md";
import { FormattedMessage, useIntl } from "react-intl";
import "reactflow/dist/style.css";
import {
  fetchCategories,
  fetchCategoriesHome,
  fetchDeparmentsHome,
} from "../../apis/administration";
import { fetchRequirementDocuments } from "../../apis/flowBuilder";
import {
  CreateFavorite,
  createTrack,
  fetchProcessFormDetail,
  listProcessDashboard,
  processRequest,
  removeFavorite,
} from "../../apis/process";
import DialogCustomized from "../../components/Dialog/DialogCustomized";
import DynamicFormPopup from "../../components/Modal/DynamicForm";
import NoResults from "../../components/NoResults";
import CustomPagination from "../../components/Pagination/pagination";
import useTranslation from "../../hooks/useTranslation";

export function ProcessList() {
  const [processes, setprocesses] = useState({ count: 0, results: [] });
  const [openDialog, setOpenDialog] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [selectedProcessId, setSelectedProcessId] = useState<
    string | undefined
  >(undefined);
  const [isLoading, setLoading] = useState(true);
  const [formData, setformData] = useState([]);
  const [nodeId, setNodeId] = useState("");
  const [actionList, setactionList] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [selected, setSelected] = useState("all");
  const { locale } = useIntl();
  const [paginationState, setPaginationState] = useState({
    pageSize: 10,
    pageNumber: 1,
  });
  const [reqDocumentsDialog, setReqDocumentsDialog] = useState(false);

  const [requiredDocuments, setRequirementsDocuments] = useState([]);

  const [categoryList, setCategoryList] = useState<any[]>([]);
  const [departmentList, setDepartmentList] = useState<any[]>([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [searchDepartment, setSearchDepartment] = useState("");
  const [searchCategory, setSearchCategory] = useState("");

  const { translate } = useTranslation();

  async function startProcess() {
    setOpenDialog(false);
    setLoading(true);
    try {
      const data: any = await processRequest({ process: selectedProcessId });
      toast.success(data);
    } catch (err) {
      console.log(err);
      toast.error("Process start failed");
    } finally {
      setLoading(false);
    }
  }

  const getProcessDetail = async (processId: string) => {
    try {
      if (!processId) {
        throw new Error("Process ID is not selected");
      }

      const processData: any = await fetchProcessFormDetail(processId);
      if (!processData.on_confirmation) {
        if (processData?.form?.length > 0) {
          // Check if form data is available
          setOpenForm(true);
          setformData(processData.form);
          setactionList(processData.actions);
          setNodeId(processData?.node_uuid ?? "");
        } else {
          toast.error("No form data available for this process.");
        }
      } else {
        setOpenDialog(true);
      }
    } catch (err) {
      console.log(err);
      toast.error("Process details get error");
    }
  };

  const createTrackOnSubmit = async (
    formState: any,
    selectedAction: string
  ) => {
    if (selectedProcessId) {
      setIsLoader(true);
      try {
        const data = await createTrack(
          selectedProcessId,
          selectedAction,
          nodeId,
          formState
        );
        setOpenForm(false);
        toast.success(data);
      } catch (err) {
        console.log(err);
        toast.error("Process start failed");
      } finally {
        setIsLoader(false);
      }
    }
  };

  useEffect(() => {
    GetProcessList(selected === "favorites", selectedCategory, paginationState);
  }, [paginationState, selected, selectedCategory]);

  useEffect(() => {
    getDeparmentList(searchDepartment);
  }, [searchDepartment]);

  useEffect(() => {
    if (selectedDepartment) {
      getCategoryList(selectedDepartment, searchCategory);
    }
  }, [searchCategory, selectedDepartment]);

  const getDeparmentList = async (search: any) => {
    try {
      const res = await fetchDeparmentsHome(search);
      setDepartmentList(res?.results ?? []);
    } catch (error) {}
  };

  const getCategoryList = async (department: string, search: string) => {
    try {
      const res = await fetchCategoriesHome(department, search);
      console.log(res);
      setCategoryList(res?.results ?? []);
    } catch (error) {}
  };

  const GetProcessList = async (
    isfavoriote?: any,
    category?: any,
    paginationState?: any,
    department?: any
  ) => {
    setLoading(true);
    try {
      const data = await listProcessDashboard(
        isfavoriote,
        category,
        paginationState,
        department
      );
      setLoading(false);
      setprocesses(data || []);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleClickFavorite = async (
    process_id: any,
    is_favourite: boolean
  ) => {
    is_favourite
      ? handleRemoveFavorite(process_id)
      : handleAddFavorite(process_id);
  };

  const handleAddFavorite = async (process_id: any) => {
    const payload = {
      process_id: process_id,
    };
    try {
      await CreateFavorite(payload);
      GetProcessList();
    } catch (error: any) {
      toast.error(error?.message ?? "Something went wrong");
    }
  };

  const handleRemoveFavorite = async (process_id: any) => {
    try {
      await removeFavorite(process_id);
      GetProcessList();
    } catch (error: any) {
      toast.error(error?.message ?? "Something went wrong");
    }
  };

  function getTranslatedText(process: any, key: string) {
    return process.translations?.[locale]?.[key] || process[key];
  }

  const getRequirementDocuments = async (processId: any) => {
    try {
      const res = await fetchRequirementDocuments(processId);
      setRequirementsDocuments(res);
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearchDeparment = (value: string) => {
    setSearchDepartment(value);
  };
  const handleSearchCategory = (value: string) => {
    setSearchCategory(value);
  };

  return (
    <div>
      <div className="flex flex-col items-start border-b px-[16px] p-[8px] gap-2">
        <div className="flex items-center gap-2">
          <h1 className="text-2xl font-bold text-gray-900">
            <FormattedMessage id="headingProceses"></FormattedMessage>
          </h1>
          {/* <button
            onClick={() => setIsFilter(!isFilter)}
            className="flex items-center gap-2 px-3 py-1 text-gray-600 rounded-lg border border-blue-500 shadow-xs shadow-lg hover:bg-gray-100"
          >
            <BsFilter className="w-4 h-4" />
            <span className="text-sm">
              <FormattedMessage id="filtersText" />
            </span>
          </button> */}
          <div className="inline-flex rounded-lg border border-blue-600 p-1 bg-blue-50">
            <button
              onClick={() => {
                GetProcessList(
                  false,
                  selectedCategory,
                  {
                    pageNumber: 1,
                    pageSize: 10,
                  },
                  selectedDepartment
                );
                setSelected("all");
                setPaginationState({ ...paginationState, pageNumber: 1 });
              }}
              className={`px-3 py-1 text-sm font-medium rounded-md transition-colors ${
                selected === "all"
                  ? "bg-primary text-white shadow-sm"
                  : "text-blue-600 hover:bg-blue-100"
              }`}
            >
              <FormattedMessage id="allButton"></FormattedMessage>
            </button>

            <button
              onClick={() => {
                GetProcessList(
                  true,
                  selectedCategory,
                  {
                    pageNumber: 1,
                    pageSize: 10,
                  },
                  selectedDepartment
                );
                setSelected("favorites");
                setPaginationState({ ...paginationState, pageNumber: 1 });
              }}
              className={`px-3 py-1 text-sm font-medium rounded-md transition-colors flex items-center gap-2 ${
                selected === "favorites"
                  ? "bg-primary text-white shadow-sm"
                  : "text-blue-600 hover:bg-blue-100"
              }`}
            >
              <MdFavoriteBorder className="w-4 h-4" />
              <FormattedMessage id="favoritesButton"></FormattedMessage>
            </button>
          </div>
        </div>
        <div className="bg-white rounded-lg shadow-md mb-1 p-4 w-full">
          {/* <h2 className="text-xl font-semibold mb-2">
            <FormattedMessage id="filtersText"></FormattedMessage>
          </h2> */}
          <div className="space-y-4">
            <div className="flex items-center gap-4">
              <div className="w-[50%]">
                <SearchableDropdown
                  label={
                    <FormattedMessage id="filterDepartment"></FormattedMessage>
                  }
                  selected={selectedDepartment}
                  onChange={(value: string) => {
                    GetProcessList(
                      selected === "favorites",
                      undefined,
                      undefined,
                      value
                    );
                    setSelectedDepartment(value);
                    setSelectedCategory("");
                  }}
                  options={
                    departmentList?.map((item: any) => ({
                      label: item?.translations?.[locale]?.department,
                      value: item?.uuid,
                    })) ?? []
                  }
                  onChangeSearch={handleSearchDeparment}
                  placeholder={"placeholderDeparment"}
                ></SearchableDropdown>
              </div>
              <div className="w-[50%]">
                <SearchableDropdown
                  label={
                    <FormattedMessage id="filterCategory"></FormattedMessage>
                  }
                  selected={selectedCategory}
                  onChange={(value: string) => {
                    GetProcessList(
                      selected === "favorites",
                      value,
                      undefined,
                      selectedDepartment
                    );
                    setSelectedCategory(value);
                  }}
                  options={
                    categoryList?.map((item: any) => ({
                      label: item?.translations?.[locale]?.category,
                      value: item.uuid,
                    })) ?? []
                  }
                  onChangeSearch={handleSearchCategory}
                  disabled={!selectedDepartment}
                  placeholder={"placeholderCategory"}
                ></SearchableDropdown>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        {!isLoading &&
          (!Array.isArray(processes.results) ||
            processes.results?.length === 0) && (
            <div className="flex items-center justify-center w-full h-[calc(100vh_-_200px)]">
              <NoResults></NoResults>
            </div>
          )}
        <Grid
          container
          // gap={1}
          spacing={2}
          justifyContent="flex-start"
          width={"100%"}
          padding={"0 16px 16px 0"}
          margin={0}
        >
          {isLoading ? (
            <div className="flex items-center justify-center w-full h-[calc(100vh_-_200px)]">
              <CircularProgress />
            </div>
          ) : (
            processes.results &&
            Array.isArray(processes.results) &&
            processes.results?.map((process: any, index: number) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={4}
                key={index}
                // sx={{ marginBottom: 3 }}
              >
                <Card
                  className="process-card"
                  sx={{
                    backgroundColor: "#fff",
                    borderRadius: "8px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    padding: "12px",
                    color: "#333",
                    fontFamily: "Arial, sans-serif",
                    height: "100%",
                    transition: "0.3s",
                    "&:hover": {
                      // boxShadow: 6,
                      // backgroundColor: "#f0f0f0",
                    },
                  }}
                >
                  <CardContent>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="flex flex-col">
                        <Typography variant="h6" component="div">
                          {getTranslatedText(process, "name")}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {getTranslatedText(process, "description")}
                        </Typography>
                      </div>
                      <div className="flex items-center">
                        <IconButton
                          onClick={() =>
                            handleClickFavorite(
                              process?.uuid ?? "",
                              process?.is_favourite
                            )
                          }
                          size="small"
                          color="primary"
                          sx={{
                            top: 0,
                            right: 0,
                          }}
                        >
                          {process?.is_favourite ? (
                            <MdFavorite
                              className="text-primary"
                              style={{ fontSize: "22px" }}
                            />
                          ) : (
                            <MdFavoriteBorder style={{ fontSize: "22px" }} />
                          )}
                        </IconButton>
                        <IconButton
                          size="small"
                          color="primary"
                          sx={{
                            top: 0,
                            right: 0,
                          }}
                          href={`/help/${process.uuid}`}
                        >
                          <HelpOutline />
                        </IconButton>
                      </div>
                    </Box>
                  </CardContent>
                  <CardActions>
                    <Box sx={{ display: "flex", width: "100%" }}>
                      <Button
                        size="small"
                        color="primary"
                        sx={{ textTransform: "uppercase" }}
                        onClick={() => {
                          setSelectedProcessId(process.uuid);
                          getProcessDetail(process.uuid);
                        }}
                      >
                        <FormattedMessage id="startProcess" />
                      </Button>
                      <Box sx={{ flexGrow: 1 }} />
                      <Button
                        size="small"
                        color="secondary"
                        sx={{ textTransform: "uppercase" }}
                        onClick={() => {
                          getRequirementDocuments(process.uuid);
                          setReqDocumentsDialog(true);
                        }}
                      >
                        <FormattedMessage id="requiredDocuments" />
                      </Button>
                    </Box>
                  </CardActions>
                </Card>
              </Grid>
            ))
          )}
        </Grid>
      </div>
      {processes.count > 10 && (
        <CustomPagination
          pageSize={paginationState.pageSize}
          pageNumber={paginationState.pageNumber}
          totalCount={processes.count}
          onPageChange={(page: number) => {
            setPaginationState({ ...paginationState, pageNumber: page });
          }}
          onPageSizeChange={(size: any) => {
            setPaginationState({ ...paginationState, pageSize: size });
          }}
        />
      )}
      {openForm && formData.length > 0 && (
        <div className="popup-overlay">
          <div className="popup-content">
            <button className="close-button" onClick={() => setOpenForm(false)}>
              ×
            </button>
            {/* <h2>{formData.name}</h2>
            <p>{formData.description}</p> */}
            <DynamicFormPopup
              formData={formData}
              actionList={actionList}
              onClose={() => setOpenForm(false)}
              onSubmit={createTrackOnSubmit}
              loader={isLoader}
            />
          </div>
        </div>
      )}

      <Dialog open={openDialog}>
        <DialogContent>
          <Typography variant="h5">
            Are you sure you want to start the process?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => setOpenDialog(false)}>
            Cancel
          </Button>
          <Button variant="contained" onClick={startProcess}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>

      <DialogCustomized
        open={reqDocumentsDialog}
        handleClose={() => setReqDocumentsDialog(false)}
        actions={
          <Stack direction="row" spacing={2}>
            <Button onClick={() => setReqDocumentsDialog(false)}>Close</Button>
          </Stack>
        }
        content={
          <Stack spacing={1}>
            <div className="space-y-4">
              {requiredDocuments?.map((item: any, index) => (
                <div
                  key={index}
                  className="border border-gray-200 rounded-lg p-4"
                >
                  <div className="flex justify-between items-center mb-2">
                    <h3 className="font-medium text-gray-900 text-md">
                      {/* <span className="text-gray-500 text-sm">
                        <FormattedMessage id="fileName"></FormattedMessage>:
                      </span>{" "} */}
                      {item?.translations?.[locale]?.label}
                    </h3>
                    <span className="px-2 py-1 bg-blue-100 text-blue-800 text-xs font-semibold rounded-full">
                      <FormattedMessage id="max"></FormattedMessage>:{" "}
                      {item.max_file_size} KB
                    </span>
                  </div>

                  <span className="text-gray-500 text-sm">
                    <FormattedMessage id="supportedFiles"></FormattedMessage>:
                  </span>
                  <div className="flex flex-wrap gap-2 mt-2">
                    {item?.accept_file_validation?.map(
                      (format: any, idx: number) => (
                        <span
                          key={idx}
                          className="px-2 py-1 bg-gray-100 text-gray-600 text-xs rounded-md"
                        >
                          {format}
                        </span>
                      )
                    )}
                  </div>
                </div>
              ))}
            </div>
          </Stack>
        }
        title={translate("requiredDocuments")}
      />
    </div>
  );
}

export default ProcessList;

function SearchableDropdown({
  label,
  options,
  selected,
  onChange,
  onChangeSearch,
  disabled,
  placeholder,
}: {
  label: any;
  options: any[];
  selected: string;
  onChange: any;
  onChangeSearch: any;
  disabled?: boolean;
  placeholder: any;
}) {
  const { translate } = useTranslation();
  const [searchTerm, setSearchTerm] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const selectedLabel = options?.find(
    (item: any) => item.value === selected
  )?.label;

  return (
    <div className="relative">
      <label className="block text-sm font-medium mb-1">{label}</label>
      <div className="relative">
        <input
          type="text"
          value={isOpen ? searchTerm : selectedLabel ?? ""}
          onChange={(e) => {
            setSearchTerm(e.target.value);
            onChangeSearch(e.target.value);
          }}
          onFocus={() => setIsOpen(true)}
          // onBlur={() => setTimeout(() => setIsOpen(false), 200)}
          placeholder={translate(placeholder)}
          className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          disabled={disabled}
        />
        {isOpen && (
          <ul className="absolute z-10 w-full bg-white border border-gray-300 rounded-md mt-1 max-h-48 overflow-y-auto shadow-md">
            <li
              className="px-3 py-2 cursor-pointer hover:bg-gray-100"
              onClick={() => {
                setIsOpen(false);
                onChange("");
              }}
            >
              <FormattedMessage id="selectDefaultOption" />
            </li>
            {options?.length > 0 ? (
              options?.map((item) => (
                <li
                  key={item?.value}
                  className="px-3 py-2 cursor-pointer hover:bg-gray-100"
                  onClick={() => {
                    setIsOpen(false);
                    onChange(item?.value);
                    setSearchTerm("");
                    onChangeSearch("");
                  }}
                >
                  {item?.label}
                </li>
              ))
            ) : (
              <li className="px-3 py-2 text-gray-500">No results found</li>
            )}
          </ul>
        )}
      </div>
    </div>
  );
}
