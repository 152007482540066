import { FormHelperText, Typography } from "@mui/material";
import React, { FC } from "react";

interface InputFieldInterface {
  value?: string;
  label: string | React.ReactNode;
  name?: string;
  placeholder?: string; // Make placeHolder optional
  onChange?: (value: string, e: any) => void; // Make onChange optional
  onBlur?: (Value: string, e: any) => void; // Keep onBlur for focus out updates
  type?: string;
  readOnly?: boolean;
  min?: string;
  max?: string;
  error?: boolean;
  helperText?: string;
}

const InputField: FC<InputFieldInterface> = ({
  value = "", // Default to empty string if value is not provided
  label,
  placeholder = "Enter the value here",
  onChange,
  onBlur,
  error,
  readOnly = false,
  helperText,
  ...props
}) => {
  return (
    <div className="w-full">
      {label && (
        <Typography variant="subtitle1" textTransform={"capitalize"} mb={1}>
          {label}
        </Typography>
      )}
      <input
        {...props}
        readOnly={readOnly}
        value={value}
        className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-1 leading-tight focus:outline-none focus:shadow-outline ${
          error ? "border-red-500" : ""
        }`}
        placeholder={placeholder}
        onChange={(e) => onChange && onChange(e.target.value, e)}
        onBlur={(e) => onBlur && onBlur(e.target.value, e)} // Add onBlur to handle blur events
      />
      {helperText && (
        <FormHelperText error={error}>{helperText}</FormHelperText>
      )}
    </div>
  );
};

export default InputField;
