import { useState } from "react";
import useTranslation from "../../../hooks/useTranslation";
import { elements_type } from "../constants";
import { Button, Stack } from "@mui/material";
import CheckBox from "../newcompnents/Addons/CheckBox";
import { AiFillEdit } from "react-icons/ai";
import { FormattedMessage } from "react-intl";
import DialogCustomized from "../../Dialog/DialogCustomized";
import Dropdown from "../../Dropdown/Dropdown";
import InputField from "../newcompnents/InputField";

const DependentPopup = ({
  data,
  formData,
  onChange,
  activeLanguage,
}: {
  data: any;
  formData: any;
  onChange: any;
  activeLanguage: any;
}) => {
  const [dependentValue, setDependentValues] = useState(data?.dependentDetails);
  const [open, setOpen] = useState(false);

  const { translate } = useTranslation();

  const fieldList = [
    elements_type.RADIOBUTTON,
    elements_type.TEXTFIELD,
    elements_type.DATE,
    elements_type.TOGGLE,
    elements_type.DATE_TIME,
    elements_type.DROPDOWN,
    elements_type.CASCADINGDROPDOWN,
    elements_type.CHECKBOX
  ];

  const handleSubmit = () => {
    onChange("dependentDetails", dependentValue, true);
    setOpen(false);
  };
  return (
    <>
      <Stack direction={"row"} alignItems={"center"} gap={2} mt={2}>
        <CheckBox
          label={translate("enableDependent")}
          isChecked={data?.enableDependent}
          onChange={(e: any) => {
            if (e.target.checked) {
              setOpen(true);
            }
            onChange("enableDependent", e.target.checked, true);
          }}
        />
        <Button
          startIcon={<AiFillEdit />}
          size="small"
          onClick={() => {
            setOpen(true);
          }}
        >
          <FormattedMessage id="editDependentDetails"></FormattedMessage>
        </Button>
      </Stack>

      <DialogCustomized
        open={open}
        handleClose={() => setOpen(false)}
        actions={
          <Stack direction="row" spacing={2}>
            <Button onClick={() => setOpen(false)}>
              {translate("cancel")}
            </Button>
            <Button variant="contained" disableElevation onClick={handleSubmit}>
              {translate("submitButton")}
            </Button>
          </Stack>
        }
        content={
          <Stack spacing={1}>
            <Dropdown
              label={translate("selectParentElement")}
              options={
                formData
                  ?.filter(
                    (x: any) =>
                      fieldList.includes(x.element_type) && x.id !== data.id
                  )
                  ?.map((x: any) => ({
                    label: x?.translate?.[activeLanguage]?.label ?? "Label",
                    value: x?.id,
                  })) ?? []
              }
              value={dependentValue?.parentId}
              name=""
              onChange={(e) => {
                let updatedValue = {
                  ...dependentValue,
                  parentId: e.target.value,
                };
                setDependentValues(updatedValue);
              }}
            />
            <Dropdown
              label={translate("condition")}
              options={conditions?.[activeLanguage]}
              value={dependentValue?.condition}
              name=""
              onChange={(e) => {
                let updatedValue = {
                  ...dependentValue,
                  condition: e.target.value,
                };
                setDependentValues(updatedValue);
              }}
            />

            <InputField
              label={translate("value")}
              placeholder={translate("valuePlaceholder")}
              // value={data[activeLanguage].label}
              value={dependentValue?.value}
              onChange={(value) => {
                let updatedValue = {
                  ...dependentValue,
                  value: value,
                };
                setDependentValues(updatedValue);
              }}
            />
          </Stack>
        }
        title={translate("dependantPopupTitle")}
      />
    </>
  );
};

export default DependentPopup;

let conditions: any = {
  ar: [
    { value: "equals", label: "يساوي" },
    { value: "contains", label: "يحتوي" },
    { value: "greaterThan", label: "أكبر من" },
    { value: "greaterThan", label: "أصغر من" },
  ],
  en: [
    { value: "equals", label: "Equals" },
    { value: "contains", label: "Contains" },
    { value: "greaterThan", label: "Greater than" },
    { value: "greaterThan", label: "Lesser than" },
  ],
};
